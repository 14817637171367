import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detailsAttendance,
  updateAttendance,
} from "../../common/redux/actions/attendanceActions";
import { ATTENDANCE_UPDATE_RESET } from "../../common/redux/constants/attendanceConstants";
import { listTeacherGroupClassStudents } from "../../common/redux/actions/studentsActions";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";

const AttendanceEditScreen = () => {
  const { groupId, classId, attendanceId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [student, setStudent] = useState(0);
  const [status, setStatus] = useState("");
  const [class_fr, setClassFr] = useState(classId);

  const statusList = [
    {
      id: 1,
      text: "Absent",
      value: "A",
    },
    {
      id: 2,
      text: "Present",
      value: "P",
    },
    {
      id: 3,
      text: "Late",
      value: "L",
    },
  ];

  const attendanceDetails = useSelector((state) => state.attendanceDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    attendance,
  } = attendanceDetails;

  const attendanceUpdate = useSelector((state) => state.attendanceUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = attendanceUpdate;

  const teacherGroupClassStudentsList = useSelector(
    (state) => state.teacherGroupClassStudentsList
  );
  const {
    loading: loadingStudents,
    error: errorStudents,
    students,
  } = teacherGroupClassStudentsList;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: ATTENDANCE_UPDATE_RESET });
      navigate(-1);
    }
    if (!attendance) {
      dispatch(detailsAttendance(groupId, classId,attendanceId));
    } else {
      setClassFr(attendance.class_fr);
      setStatus(attendance.status);
      setStudent(attendance.student);
    }
    dispatch(listTeacherGroupClassStudents(groupId));
  }, [dispatch, attendance, successUpdate, groupId, navigate, classId, attendanceId]);

  const submitHandler = (e) => {
    e.preventDefault();
    // Update form data
    const formData = new FormData();
    formData.append("student", student);
    formData.append("status", status);
    formData.append("class_fr", class_fr);
    dispatch(updateAttendance(groupId, classId, formData));
  };



  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <div>
          <h2 className="text-[24px] font-semibold">
            Edit Class Attendance {attendanceId}
          </h2>
        </div>
        <form className="form" onSubmit={submitHandler}>
          {loadingUpdate && <LoadingBox></LoadingBox>}
          {errorUpdate && (
            <MessageBox variant="danger">{errorUpdate}</MessageBox>
          )}
          {loadingDetails ? (
            <LoadingBox></LoadingBox>
          ) : errorDetails ? (
            <MessageBox variant="danger">{errorDetails}</MessageBox>
          ) : (
            <div className="mt-[30px]">
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="student">Student</label>
                {loadingStudents ? (
                  <LoadingBox></LoadingBox>
                ) : errorStudents ? (
                  <MessageBox variant="danger">{errorStudents}</MessageBox>
                ) : (
                  <select
                    name="student"
                    value={student}
                    onChange={(e) => setStudent(e.target.value)}
                    placeholder="Enter Student Name"
                    className="p-[1rem]"
                    required
                  >
                    {students.map((student) => {
                      return (
                        <option value={student.id}>
                          {student.id} - {student.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="status">{t("status")}</label>
                <select
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  placeholder="Enter Status"
                  className="p-[1rem]"
                  required
                >
                  {statusList.map((status) => {
                    return (
                      <option value={status.value} className="p-[1rem]">
                        {status.text}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="flex flex-col pt-[10px]">
                <button
                  className="bg-[#08AD76] text-[white] p-[1rem] "
                  type="submit"
                >
                  { t("update")}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default AttendanceEditScreen;
