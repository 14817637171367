import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createAssignment } from "../../common/redux/actions/assignmentActions";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { ASSIGNMENT_CREATE_RESET } from "../../common/redux/constants/assignmentConstants";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";

const AssignmentAddScreen = () => {
  const { groupId, classId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");

  const assignmentCreate = useSelector((state) => state.assignmentCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    assignment,
  } = assignmentCreate;

  useEffect(() => {
    if (successCreate) {
      navigate(`/teacher/group/${groupId}/class/${classId}/assignments`);
      dispatch({ type: ASSIGNMENT_CREATE_RESET });
    }
  }, [dispatch, assignment, successCreate, groupId, navigate, classId]);

  //   Submit Data with form data
  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("file", file);
    dispatch(createAssignment(groupId, classId, formData));
  };

  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <form className="form" onSubmit={submitHandler}>
          <div>
            <h2 className="text-[24px] font-semibold">
              Create Class for group class Assignment {groupId}
            </h2>
          </div>
          {loadingCreate ? (
            <LoadingBox></LoadingBox>
          ) : errorCreate ? (
            <MessageBox variant="danger">{errorCreate}</MessageBox>
          ) : (
            <div className="mt-[30px]">
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="title">{t("title")}</label>
                <input
                  id="title"
                  type="text"
                  className="p-[1rem]"
                  placeholder="Enter Title"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="description">{t("description")}</label>
                <input
                  id="description"
                  type="text"
                  className="p-[1rem]"
                  placeholder="Enter Description"
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="file">{t("file")}</label>
                <input
                  id="file"
                  type="file"
                  className="p-[1rem]"
                  placeholder="Enter File"
                  onChange={(e) => setFile(e.target.files[0])}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="file">{t("fileText")}</label>
                <input
                  id="file"
                  type="text"
                  disabled
                  className="p-[1rem]"
                  required
                  value={file}
                  onChange={(e) => setFile(e.target.files[0])}
                ></input>
              </div>

              <div className="flex flex-col pt-[10px]">
                <button
                  className="bg-[#08AD76] text-[white] p-[1rem] "
                  type="submit"
                >
                  {t("createAssignment")}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default AssignmentAddScreen;
