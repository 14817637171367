import React from 'react'
import { useNavigate } from 'react-router-dom';
import {AiOutlineRollback} from 'react-icons/ai';
import { useTranslation } from "react-i18next";
const ComeBack = () => {
  const {t} = useTranslation();  
  const navigate = useNavigate()  
  return (
    <React.Fragment>
        <button className='px-[20px]  py-[10px] w-[60%] lg:w-[20%] rounded-[10px] border-none outline-none bg-[10px] text-[white] bg-[#222631] flex items-center justify-center' onClick={() => navigate(-1)}>
            <AiOutlineRollback className='mr-[10px]'/>
            {t("comeBack")}
        </button>
    </React.Fragment>
  )
}

export default ComeBack