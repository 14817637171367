import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createTeacherGroupClass } from "../../common/redux/actions/classActions";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { TEACHER_GROUP_CLASS_CREATE_RESET } from "../../common/redux/constants/classConstants";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";

const ClassAddScreen = () => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [class_number, setClassNumber] = useState("");
  const [class_title, setClassTitle] = useState("");
  const [end_date, setEndDate] = useState("");
  const [start_date, setStartDate] = useState("");
  const [is_taken] = useState(false);
  const teacherGroupClassCreate = useSelector(
    (state) => state.teacherGroupClassCreate
  );
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    groupClass,
  } = teacherGroupClassCreate;

  useEffect(() => {
    if (successCreate) {
      navigate(`/teacher/group/${groupId}`);
      dispatch({ type: TEACHER_GROUP_CLASS_CREATE_RESET });
    }
  }, [dispatch, groupClass, successCreate, groupId, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createTeacherGroupClass(groupId, {
        class_number,
        class_title,
        end_date,
        start_date,
        is_taken,
      })
    );
  };

  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <form className="form" onSubmit={submitHandler}>
          <div>
            <h2 className="text-[24px] font-semibold">
              {t("createClassTitle")} {groupId}
            </h2>
          </div>
          {loadingCreate ? (
            <LoadingBox></LoadingBox>
          ) : errorCreate ? (
            <MessageBox variant="danger">{errorCreate}</MessageBox>
          ) : (
            <div className="mt-[30px]">
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="title">{t("title")}</label>
                <input
                  id="title"
                  type="text"
                  className="p-[1rem]"
                  placeholder="Enter Title"
                  required
                  value={class_title}
                  onChange={(e) => setClassTitle(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="number">{t("number")}</label>
                <input
                  id="number"
                  type="text"
                  className="p-[1rem]"
                  placeholder="Enter Number"
                  required
                  value={class_number}
                  onChange={(e) => setClassNumber(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="startdate">{t("startDate")}</label>
                <input
                  id="startdate"
                  type="date"
                  className="p-[1rem]"
                  placeholder="Enter Start Date"
                  value={start_date}
                  required
                  onChange={(e) => setStartDate(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="enddate">{t("endDate")}</label>
                <input
                  id="enddate"
                  type="date"
                  className="p-[1rem]"
                  placeholder="Enter End Date"
                  value={end_date}
                  required
                  onChange={(e) => setEndDate(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <button
                  className="bg-[#08AD76] text-[white] p-[1rem] "
                  type="submit"
                >
                  {t("createClass")}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default ClassAddScreen;
