import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detailsUser,
  updateUserProfile,
} from "../../common/redux/actions/userActions";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { USER_UPDATE_PROFILE_RESET } from "../../common/redux/constants/userConstants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Profile = ({photo, previewPhoto, setPhotoHandler, setPhoto}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [user_name, setUserName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [father_name, setFatherName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [date_of_birth, setBirthday] = useState("");
  const userSignin = useSelector((state) => state.userSignin);
  const { loading, error, userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpdate,
  } = userUpdateProfile;

  useEffect(() => {
    if (!user) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch(detailsUser());
    } else {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setFatherName(user.father_name);
      setEmail(user.email);
      setAddress(user.address);
      setPhone(user.phone);
      setBirthday(user.date_of_birth);
      setUserName(user.user_name);
      setPhoto(user.photo);
    }
  }, [dispatch, userInfo.access, user, setPhoto]);
  // Identify photo


  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_name", user_name);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("father_name", father_name);
    formData.append("address", address);
    formData.append("phone", phone);
    formData.append("date_of_birth", date_of_birth);
    if (typeof photo === "string") {
      const file = await  fetch("http://dailystat.org/media/user_photo/WhatsApp_Image_2023-01-08_at_02.47.36.jpeg")
      .then((response) => response.blob())
      .then((blob) => new File([blob], "image.jpg", { type: "image/jpeg" }));
      formData.append("photo", file);
    } else {
      formData.append("photo", photo);
    }
    dispatch(updateUserProfile(formData));
  };

  return (
    <React.Fragment>
      <form
        className="w-[100%] lg:w-[90%] lg:pl-[50px]"
        onSubmit={submitHandler}
        enctype="multipart/form-data"
      >
        <div>
          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <>
              {loadingUpdate && <LoadingBox></LoadingBox>}
              {errorUpdate && (
                <MessageBox variant="danger">{errorUpdate}</MessageBox>
              )}
              {successUpdate && (
                <MessageBox variant="success">
                  {t("profileUpdatedSuccessfully")}
                </MessageBox>
              )}
              <div className="flex flex-col pt-[30px] lg:flex-row">
                <div className="">
                  <div className="w-[100px] lg:w-[200px] h-[100px] lg:h-[200px] bg-[#232631] rounded-full relative">
                    {user?.photo ? (
                      <img
                        src={previewPhoto || photo}
                        className="w-[100%] h-[100%] rounded-full"
                        alt=""
                      />
                    ) : (
                      <h2 className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[white] text-[40px] lg:text-[90px]">
                        {user?.first_name[0]}
                        {user?.last_name[0]}
                      </h2>
                    )}
                  </div>
                  <div className="mt-[10px]">
                    <input
                      type="file"
                      id="imageFile"
                      label="Choose Image"
                      onChange={setPhotoHandler}
                    ></input>
                  </div>
                </div>
                <div className="lg:ml-[60px] mt-[15px] lg:mt-[0px] w-[100%]">
                  <div className="w-[100%] grid grid-cols-1 lg:grid-cols-2 gap-x-[30px]">
                    <div className="flex flex-col">
                      <label>{t("firstName")}</label>
                      <input
                        type="text"
                        placeholder={t("firstName")}
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="mt-[5px] rounded-[5px] p-[10px] shadow border-[1px] border-solid border-[#232631]"
                      />
                    </div>
                    <div className="flex flex-col pt-[10px] lg:pt-[0px]">
                      <label>{t("lastName")}</label>
                      <input
                        type="text"
                        placeholder={t("lastName")}
                        value={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                        className="mt-[5px] rounded-[5px] p-[10px] shadow border-[1px] border-solid border-[#232631]"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col pt-[10px]">
                    <label>{t("fatherName")}</label>
                    <input
                      type="text"
                      placeholder={t("fatherName")}
                      value={father_name}
                      onChange={(e) => setFatherName(e.target.value)}
                      className="mt-[5px] rounded-[5px] p-[10px] shadow border-[1px] border-solid border-[#232631]"
                    />
                  </div>
                  <div className="flex flex-col pt-[10px]">
                    <label>{t("contactNumber")}</label>
                    <input
                      type="text"
                      placeholder={t("contactNumber")}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="mt-[5px] rounded-[5px] p-[10px] shadow border-[1px] border-solid border-[#232631]"
                    />
                  </div>
                  <div className="flex flex-col pt-[10px]">
                    <label>{t("birthday")}</label>
                    <input
                      type="date"
                      placeholder={t("birthday")}
                      value={date_of_birth}
                      onChange={(e) => setBirthday(e.target.value)}
                      className="mt-[5px] rounded-[5px] p-[10px] shadow border-[1px] border-solid border-[#232631]"
                    />
                  </div>
                  <div className="flex flex-col pt-[10px]">
                    <label>{t("address")}</label>
                    <input
                      type="text"
                      placeholder={t("address")}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="mt-[5px] rounded-[5px] p-[10px] shadow border-[1px] border-solid border-[#232631]"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className=" w-[100%] bg-[#232631] text-white text-center py-[10px] mt-[15px]">
          <button className="w-[100%]" type="submit">
            {t("save")}
          </button>
        </div>
      </form>
      <div className="mt-[10px] lg:w-[80%] lg:pl-[50px]">
        <Link to="/change_password">
          <h2 className="text-[#232631] text-left font-semibold underline py-[10px] w-[100%] ">
            {t("Do you want to change your password")}?
          </h2>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default Profile;
