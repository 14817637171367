import {
  STUDENT_PRIMARY_STATISTICS_LIST_FAIL,
  STUDENT_PRIMARY_STATISTICS_LIST_REQUEST,
  STUDENT_PRIMARY_STATISTICS_LIST_SUCCESS,
  STUDENT_SECONDARY_STATISTICS_LIST_FAIL,
  STUDENT_SECONDARY_STATISTICS_LIST_REQUEST,
  STUDENT_SECONDARY_STATISTICS_LIST_SUCCESS,
  TEACHER_STATISTICS_LIST_FAIL,
  TEACHER_STATISTICS_LIST_REQUEST,
  TEACHER_STATISTICS_LIST_SUCCESS,
} from "../constants/statisticsConstants";
import Axios from "axios";


const BASE_URL = "https://dailystat.org";
export const listStudentPrimaryStatistics = (id) => async (dispatch, getState) => {
  dispatch({ type: STUDENT_PRIMARY_STATISTICS_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(
      `${BASE_URL}/course/student/api/enrollments/${id}/statistics`,
      {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      }
    );
    dispatch({
      type: STUDENT_PRIMARY_STATISTICS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: STUDENT_PRIMARY_STATISTICS_LIST_FAIL,
      payload: message,
    });
  }
};


export const listStudentSecondaryStatistics = (id) => async (dispatch, getState) => {
  dispatch({ type: STUDENT_SECONDARY_STATISTICS_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(
      `${BASE_URL}/course/student/api/enrollments/${id}/group-statistics`,
      {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      }
    );
    dispatch({
      type: STUDENT_SECONDARY_STATISTICS_LIST_SUCCESS,
      payload: data.data,
    });
 
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: STUDENT_SECONDARY_STATISTICS_LIST_FAIL,
      payload: message,
    });
  }
};




export const listTeacherStatistics = (id) => async (dispatch, getState) => {
  dispatch({ type: TEACHER_STATISTICS_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(
      `${BASE_URL}/course/teacher/api/groups/${id}/statistics`,
      {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      }
    );
    dispatch({
      type: TEACHER_STATISTICS_LIST_SUCCESS,
      payload: data.data,
    });
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: TEACHER_STATISTICS_LIST_FAIL,
      payload: message,
    });
  }
};

