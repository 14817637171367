import Axios from "axios";
import {
  TEACHER_GROUPS_LIST_FAIL,
  TEACHER_GROUPS_LIST_SUCCESS,
  TEACHER_GROUPS_LIST_REQUEST,
  TEACHER_GROUP_DETAILS_SUCCESS,
  TEACHER_GROUP_DETAILS_FAIL,
  TEACHER_GROUP_DETAILS_REQUEST,
} from "../constants/groupConstants";



const BASE_URL = "https://dailystat.org";
export const listTeacherGroups = () => async (dispatch, getState) => {
  dispatch({ type: TEACHER_GROUPS_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(`${BASE_URL}/course/teacher/api/groups/`, {
      headers: {
        Authorization: `Bearer ${userInfo?.access}`,
      },
    });
    dispatch({ type: TEACHER_GROUPS_LIST_SUCCESS, payload: data});
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: TEACHER_GROUPS_LIST_FAIL, payload: message });
  }
};

export const detailsTeacherGroup = (id) => async (dispatch, getState) => {
  dispatch({ type: TEACHER_GROUP_DETAILS_REQUEST, payload: id });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(`${BASE_URL}/course/teacher/api/groups/${id}/classes`,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.access}`,
        },
      }
    );
    dispatch({ type: TEACHER_GROUP_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: TEACHER_GROUP_DETAILS_FAIL, payload: message });
  }
};




