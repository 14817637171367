import React from "react";
import { AiOutlineClose } from "react-icons/ai";
const PasswordModal = ({ setModal, modal }) => {
  return (
    <React.Fragment>
      <div className={modal ? "passwordModal" : "passwordModal active"}>
        <h3 className="text-center pt-[60px] text-[24px]">Məlumat</h3>
        <p className="text-center pt-[20px]">
          Parolu unutmusunuzsa, zəhmət olmasa kurs rəhbərliyinə yaxınlaşın.
        </p>

        <div
          className="absolute top-[5%] right-[5%] transform-x-[-10%] transform-y-[-10%] text-[24px] cursor-pointer "
          onClick={() => setModal(!modal)}
        >
          <AiOutlineClose />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PasswordModal;
