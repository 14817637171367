import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  listAssignments,
  deleteAssignment,
} from "../../common/redux/actions/assignmentActions";
import MessageBox from "../../components/HelperComponents/MessageBox";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ASSIGNMENT_DELETE_RESET } from "../../common/redux/constants/assignmentConstants";
// import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";

const AssignmentListSceen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assignmentsList = useSelector((state) => state.assignmentsList);
  const { loading, error, assignments } = assignmentsList;

  const { groupId, classId } = useParams();
  const navigate = useNavigate();
  // Delete Assignment
  const assignmentDelete = useSelector((state) => state.assignmentDelete);
  const {
    loading: loadingDeleteAssignment,
    error: errorDeleteAssignment,
    success: successDeleteAssignment,
  } = assignmentDelete;
  useEffect(() => {
    if (successDeleteAssignment) {
      dispatch({ type: ASSIGNMENT_DELETE_RESET });
      alert("Assignment deleted successfully");
    }
    dispatch(listAssignments(groupId, classId));
  }, [dispatch, groupId, classId, successDeleteAssignment]);

  const deleteHandler = (assignment) => {
    if (window.confirm("Are you sure to delete?")) {
      dispatch(deleteAssignment(groupId, classId, assignment.id));
    }
  };

  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <h2 className="mt-[15px] font-bold text-[28px]">
          Your Group Class Assignments List
        </h2>
        <div className="flex">
          <Link
            to={`/teacher/group/${groupId}/class/${classId}/createassignment`}
          >
            <div className="w-[200px] mt-[10px] ml-[10px] bg-[#08AD76] rounded-[10px] p-[10px] text-[white] text-center cursor-pointer">
              {t("addAssignment")}
            </div>
          </Link>
          {/* <div>
            <ReactHtmlTableToExcel
              className="bg-[#1365cf]  text-[white] w-[200px] p-[10px] mt-[10px] ml-[10px] rounded-[10px] cursor-pointer"
              table="assignmentTable"
              fileName="assignmentList"
              sheet="Sheet"
              buttonText="Export to Excel"
            />
          </div> */}
        </div>
        
        {loadingDeleteAssignment && <LoadingBox></LoadingBox>}
        {errorDeleteAssignment && (
          <MessageBox variant="danger">{errorDeleteAssignment}</MessageBox>
        )}
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div className="flex flex-col mt-[30px]">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-12">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full" id="assignmentTable">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                          N#
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                          {t("title")}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                          {t("description")}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                          {t("file")}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                          {t("actions")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {assignments.length === 0 && <MessageBox>There is no assignment for this class</MessageBox>}
                      {assignments.map((assignment) => {
                        return (
                          <tr className="bg-gray-100 border-b">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {assignment.id}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {assignment.title}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {assignment.description}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {assignment?.file && assignment?.file.slice(0,20)}......
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              <div>
                                <button
                                  className="bg-[#28a745] p-[10px] text-white m-[5px] rounded-[5px]"
                                  onClick={() =>
                                    navigate(
                                      `/teacher/group/${groupId}/class/${classId}/assignments/${assignment.id}/edit`
                                    )
                                  }
                                >
                                {t("edit")}
                                </button>
                                <button
                                  className="bg-[#dc3545] p-[10px] text-white m-[5px] rounded-[5px]"
                                  onClick={() => deleteHandler(assignment)}
                                >
                                  {t("delete")}
                                </button>
                                <button className="bg-[#1365cf] p-[10px] text-white m-[5px] rounded-[5px]">
                                  <Link
                                    to={`/teacher/group/${groupId}/class/${classId}/assignments/${assignment.id}/scores`}
                                  >
                                    {t("scores")}
                                  </Link>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AssignmentListSceen;
