import {
  TEACHER_GROUPS_LIST_FAIL,
  TEACHER_GROUPS_LIST_REQUEST,
  TEACHER_GROUPS_LIST_SUCCESS,
  TEACHER_GROUP_DETAILS_FAIL,
  TEACHER_GROUP_DETAILS_REQUEST,
  TEACHER_GROUP_DETAILS_SUCCESS,
} from "../constants/groupConstants";


export const teacherGroupsListReducer = (
  state = { loading: true, groups: [] },
  action
) => {
  switch (action.type) {
    case TEACHER_GROUPS_LIST_REQUEST:
      return { loading: true };
    case TEACHER_GROUPS_LIST_SUCCESS:
      return { loading: false, groups: action.payload };
    case TEACHER_GROUPS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teacherGroupDetailsReducer = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case TEACHER_GROUP_DETAILS_REQUEST:
      return { loading: true };
    case TEACHER_GROUP_DETAILS_SUCCESS:
      return { loading: false, group: action.payload };
    case TEACHER_GROUP_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
