import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { listTeacherGroups } from "../../common/redux/actions/groupActions";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import GroupBox from "../../components/TeacherComponents/GroupBox";
import { useTranslation } from "react-i18next";

const TeacherGroupsScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const teacherGroupsList = useSelector((state) => state.teacherGroupsList);
  const { loading, error, groups } = teacherGroupsList;

  useEffect(() => {
    dispatch(listTeacherGroups());
  }, [dispatch]);
  return (
    <React.Fragment>
      <div>
        <h2 className="mt-[15px] font-bold text-[20px] lg:text-[28px]">
          {t("yourGroups")}
        </h2>
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div className="mt-[30px]">
            {groups.length === 0 && (
              <MessageBox>You haven't group yet</MessageBox>
            )}
            {groups.map((group) => {
              return <GroupBox group={group} key={group.id} />;
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TeacherGroupsScreen;
