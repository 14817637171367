export const STUDENT_PRIMARY_STATISTICS_LIST_REQUEST = "STUDENT_PRIMARY_STATISTICS_LIST_REQUEST";
export const STUDENT_PRIMARY_STATISTICS_LIST_SUCCESS = "STUDENT_PRIMARY_STATISTICS_LIST_SUCCESS";
export const STUDENT_PRIMARY_STATISTICS_LIST_FAIL = "STUDENT_PRIMARY_STATISTICS_LIST_FAIL";

export const STUDENT_SECONDARY_STATISTICS_LIST_REQUEST = "STUDENT_SECONDARY_STATISTICS_LIST_REQUEST";
export const STUDENT_SECONDARY_STATISTICS_LIST_SUCCESS = "STUDENT_SECONDARY_STATISTICS_LIST_SUCCESS";
export const STUDENT_SECONDARY_STATISTICS_LIST_FAIL = "STUDENT_SECONDARY_STATISTICS_LIST_FAIL";

export const TEACHER_STATISTICS_LIST_FAIL = "TEACHER_STATISTICS_LIST_FAIL";
export const TEACHER_STATISTICS_LIST_REQUEST = "TEACHER_STATISTICS_LIST_REQUEST";
export const TEACHER_STATISTICS_LIST_SUCCESS = "TEACHER_STATISTICS_LIST_SUCCESS";