import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../common/redux/actions/userActions";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import companyLogo from "../../assets/logo/companyLogo.png";
import PasswordModel from '../../components/FeatureComponents/PasswordModal';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';
const LoginScreen = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [modal, setModal] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  }

  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(username, password));
  };
  useEffect(() => {
    if (userInfo) {
      window.location.href = "/";
    }
  }, [userInfo]);
  return (
    <React.Fragment>
      <div className="flex justify-between bg-[#EDEEF7] loginHomePage">
        <div className="w-[100%] h-[100%]  min-h-[100vh] flex flex-col items-center justify-center text-center">
          <div className="flex items-center flex-col justify-center">
            <div className="w-[200px] h-[200px]">
              <img className="" src={companyLogo} alt="companyLogo" />
            </div>
            <h2 className="pt-[0px] text-[black] text-[24px] font-semibold text-[white]">
              Hello Again!
            </h2>
            <p className="text-[15px] pt-[10px] opacity-[0.7] text-[white]">
              Welcome back, you have been missed!
            </p>
          </div>
          <div className="w-[80%] lg:w-[40%]">
            <form onSubmit={submitHandler}>
              {loading && <LoadingBox></LoadingBox>}
              {error && <MessageBox variant="danger">{error}</MessageBox>}
              <div className="bg-[white] p-[15px] rounded-[10px] w-[100%] mt-[15px] shadow border-[1px] border-solid border-[#006DFF]">
                <input
                  className="w-[100%] h-[100%] outline-none border-none bg-none"
                  type="text"
                  placeholder="Write your username"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className=" flex bg-[white] p-[15px] rounded-[10px] w-[100%] mt-[15px] shadow border-[1px] border-solid border-[#006DFF]">
                <input
                  className="w-[100%] h-[100%] outline-none border-none bg-none"
                  type={passwordShown ? "text" : "password"} 
                  placeholder="Write your password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="cursor-pointer text-[24px]" onClick={() => togglePasswordVisiblity()}>
                  {passwordShown ? <AiFillEyeInvisible className="text-[#006DFF]"/> : <AiFillEye className="text-[#006DFF]"/>}
                </div>
              </div>
              <div className="mt-[15px]">
                <button
                  type="submit"
                  className="bg-[#222631] w-[100%] p-[15px] rounded-[10px] text-[white]"
                >
                  Login
                </button>
              </div>
            </form>
            <div className="text-[white] text-[18px] mt-[10px] text-left font-bold cursor-pointer" onClick={() => setModal(!modal)}>
              <h3 className="cursor-pointer">Forgot your password ?</h3>
            </div>
          </div>
        </div>
        <PasswordModel modal={modal} setModal={setModal}/>
      </div>
    </React.Fragment>
  );
};

export default LoginScreen;
