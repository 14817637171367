import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { listTeacherStatistics } from "../../common/redux/actions/statisticsActions";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";
const TeacherGroupRanking = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const teacherStatisticsList = useSelector(
    (state) => state.teacherStatisticsList
  );
  const { loading, error, teacherStatistics } = teacherStatisticsList;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listTeacherStatistics(id));
  }, [dispatch, id]);

  return (
    <React.Fragment>
      <ComeBack />
      <h2 className="mt-[30px] font-bold text-[20px]">
        {t("groupRankingList")}
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div>
            <div className="flex flex-col mt-[10px]">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full" id="attendanceTable">
                      <thead className="bg-white">
                        <tr>
                          <th
                            scope="col"
                            className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                          >
                            {t("student")}
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                          >
                            {t("totalAssignment")}
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                          >
                            {t("totalScore")}
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                          >
                            {t("averageScore")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {teacherStatistics.length === 0 && (
                          <MessageBox>
                            {t("noStudentInThisGroup")}
                          </MessageBox>
                        )}
                        {teacherStatistics.map((statistics) => {
                          return (
                            <tr className="bg-gray-100 border-b">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {statistics.student_name}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {statistics.total_assignment}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {statistics.total_score}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {statistics.average_score}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </h2>
    </React.Fragment>
  );
};

export default TeacherGroupRanking;
