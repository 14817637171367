import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { listTeacherGroupClassStudents } from "../../common/redux/actions/studentsActions";
import { ATTENDANCE_CREATE_RESET } from "../../common/redux/constants/attendanceConstants";
import { createAttendance } from "../../common/redux/actions/attendanceActions";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";
const AttendanceAddScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { groupId, classId } = useParams();
  const navigate = useNavigate();
  const [student, setStudent] = useState("");
  const [status, setStatus] = useState("");
  const [class_fr] = useState(classId);

  const statusList = [
    {
      id: 1,
      text: "Absent",
      value: "A",
    },
    {
      id: 2,
      text: "Present",
      value: "P",
    },
    {
      id: 3,
      text: "Late",
      value: "L",
    },
  ];

  const attendanceCreate = useSelector((state) => state.attendanceCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    attendance,
  } = attendanceCreate;

  const teacherGroupClassStudentsList = useSelector(
    (state) => state.teacherGroupClassStudentsList
  );

  const { loading, error, students } = teacherGroupClassStudentsList;
  useEffect(() => {
    if (successCreate) {
      dispatch({ type: ATTENDANCE_CREATE_RESET });
      navigate(-1);
    }
    dispatch(listTeacherGroupClassStudents(groupId));
  }, [dispatch, attendance, successCreate, groupId, navigate, classId]);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("student", student);
    formData.append("status", status);
    formData.append("class_fr", class_fr);
    dispatch(createAttendance(groupId, classId, formData));
  };

  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <form className="form" onSubmit={submitHandler}>
          <div>
            <h2 className="text-[24px] font-semibold">
              Create Class for Group Class Assignment Score
            </h2>
          </div>
          {loadingCreate ? (
            <LoadingBox></LoadingBox>
          ) : errorCreate ? (
            <MessageBox variant="danger">{errorCreate}</MessageBox>
          ) : (
            <div className="mt-[30px]">
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="student">Student</label>
                {loading ? (
                  <LoadingBox></LoadingBox>
                ) : error ? (
                  <MessageBox variant="danger">{error}</MessageBox>
                ) : (
                  <select
                    name="student"
                    value={student}
                    onChange={(e) => setStudent(e.target.value)}
                    placeholder="Enter Student Name"
                    className="p-[1rem]"
                    required
                  >
                    <option value="" disabled>
                      Tələbə seçin zəhmət olmasa
                    </option>
                    {students.map((student) => {
                      return (
                        <option value={student.id} selected={student.id}>
                          {student.id} - {student.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>

              <div className="flex flex-col pt-[10px]">
                <label htmlFor="status">{t("status")}</label>
                <select
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  placeholder="Enter Status"
                  className="p-[1rem]"
                  required
                >
                  <option value="" disabled>
                    Status seçin zəhmət olmasa
                  </option>
                  {statusList.map((status) => {
                    return (
                      <option
                        value={status.value}
                        selected={status.value}
                        className="p-[1rem]"
                      >
                        {status.text}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="flex flex-col pt-[20px]">
                <button
                  className="bg-[#08AD76] text-[white] p-[1rem] "
                  type="submit"
                >
                  {t("addAttendance")}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default AttendanceAddScreen;
