import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { detailsEnrollment } from "../../common/redux/actions/enrollmentActions";
import {
  listStudentPrimaryStatistics,
  listStudentSecondaryStatistics,
} from "../../common/redux/actions/statisticsActions";
import Statistics from "../../components/StudentComponents/Statistics";
import AttendanceScreen from "../AttendancePages/AttendanceScreen";
import StatisticsBox from "../../components/StudentComponents/StatisticsBox";

const StudentDashboardPanel = ({ user }) => {
  const dispatch = useDispatch();
  const enrollmentDetail = useSelector((state) => state.enrollmentDetails);
  const { loading, error, enrollment } = enrollmentDetail;
  const id = enrollment?.id;
  const studentPrimaryStatisticsList = useSelector(
    (state) => state.studentPrimaryStatisticsList
  );
  const {
    loading: loadingPrimaryStatistics,
    error: errorPrimaryStatistics,
    statisticsPrimary,
  } = studentPrimaryStatisticsList;
  const studentSecondaryStatisticsList = useSelector(
    (state) => state.studentSecondaryStatisticsList
  );

  const {
    loading: loadingSecondaryStatistics,
    error: errorSecondaryStatistics,
    statisticsSecondary,
  } = studentSecondaryStatisticsList;

  useEffect(() => {
    dispatch(detailsEnrollment());
  }, [dispatch]);
  useEffect(() => {
    dispatch(listStudentPrimaryStatistics(id));
    dispatch(listStudentSecondaryStatistics(id));
  }, [dispatch, id]);



 
  return (
    <React.Fragment>
      {/* If enrollment is empty there is no */}
      {enrollment && enrollment.group && (
        <div>
          <div>
            <div>
              {loading ? (
                <div>Loading...</div>
              ) : error ? (
                <div>{error}</div>
              ) : (
                <div className="flex flex-col  lg:flex-row justify-between lg:items-center ">
                  <div>
                    {enrollment.group && (
                      <div>
                        <h1 className="text-[24px] font-bold">
                          {enrollment.group.name}-{enrollment.group.course.name}
                          <br />
                          <p className="text-[16px] opacity-[0.8]">
                            {enrollment.group.course.category} -{" "}
                            {enrollment.group.course.description}
                          </p>
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Statistics */}

          <Statistics
            statisticsSecondary={statisticsSecondary}
            loadingSecondaryStatistics={loadingSecondaryStatistics}
            errorSecondaryStatistics={errorSecondaryStatistics}
            user={user}
          />
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Loading...</div>
          ) : (
            <StatisticsBox
              loadingPrimaryStatistics={loadingPrimaryStatistics}
              errorPrimaryStatistics={errorPrimaryStatistics}
              statisticsPrimary={statisticsPrimary}
            />
          )}
          <div>
            <AttendanceScreen
              enrollment={enrollment}
              loading={loading}
              error={error}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default StudentDashboardPanel;
