import React from "react";
import AbsenceBox from "../../components/StudentComponents/AbsenceBox";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { useTranslation } from "react-i18next";
const AttendanceScreen = (props) => {
  const { enrollment, loading, error } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {enrollment && enrollment.group && (
        <div className="shadow  bg-[white] p-[10px] mt-[20px]">
          <div>
            <h1 className="font-bold text-[24px]">{t("attendance")}</h1>
          </div>
          <div className="w-[100%] lg:w-[50%]  flex justify-between flex-wrap pt-[20px]">
            <div className="flex justify-center pb-[5px] lg:pb-[0px]">
              <div className="w-[20px] h-[20px] bg-[#F1F6FA] rounded-[5px]"></div>
              <p className="font-semibold text-[16px] pl-[5px]">
                {t("present")}
              </p>
            </div>
            <div className="flex justify-center pb-[5px] lg:pb-[0px]">
              <div className="w-[20px] h-[20px]  bg-[#FFAAAA] rounded-[5px]"></div>
              <p className="font-semibold text-[16px] pl-[5px]">
                {t("absence")}
              </p>
            </div>
            <div className="flex justify-center pb-[5px] lg:pb-[0px]">
              <div className="w-[20px] h-[20px]  bg-[#FFC730]  rounded-[5px]"></div>
              <p className="font-semibold text-[16px] pl-[5px]">{t("late")}</p>
            </div>
          </div>
          <div className="grid lg:grid-cols-10 justify-center grid-cols-2 lg:gap-[10px] my-[20px]">
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              enrollment &&
              enrollment.group &&
              enrollment.group.class_frs.map((groupClass) => {
                return (
                  <AbsenceBox
                    groupClass={groupClass}
                    loading={loading}
                    error={error}
                  />
                );
              })
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default AttendanceScreen;
