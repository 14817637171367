import React, { useEffect } from "react";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { useDispatch, useSelector } from "react-redux";
import { listNews } from "../../common/redux/actions/newsActions";
import NewsBox from "../../components/NewsComponents/NewsBox";
import { useTranslation } from "react-i18next";
const NewsScreen = () => {
  const { t} = useTranslation();
  const dispatch = useDispatch();
  const newsList = useSelector((state) => state.newsList);
  const { loading, error, news } = newsList;

  useEffect(() => {
    dispatch(listNews());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div>
        <div>
          <h2 className="text-[32px] font-bold"> <h1>{t("news")}</h1></h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-[15px] gap-x-[50px] mt-[20px]">
          {loading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : news.length === 0 ? (
            <MessageBox variant="danger">No news found</MessageBox>
          ) : (
            news.map((item) => {
              return <NewsBox item={item} />;
            })
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewsScreen;
