import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next"; 

const Contact = () => {
  const position = [40.37891555138008, 49.853729857396736];
  const zoom = 13;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h1 className="text-[32px] text-[black] font-semibold">{t("contact")}</h1>
      <div className="bg-[white] flex w-[100%] lg:w-[40%] p-[10px] rounded-[10px] justify-between">
        <div className="font-semibold">Phone Number</div>
        <div className="font-bold">
          <a target="_blank" href="https://wa.me/+994507799824">050 779 98 24</a>
        </div>
      </div>

      <div className="mt-[20px] mb-[20px]">
        <MapContainer center={position} zoom={zoom} style={{ height: "500px" }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={position}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </React.Fragment>
  );
};

export default Contact;
