import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detailsScore,
  updateScore,
} from "../../common/redux/actions/scoreActions";
import { SCORE_UPDATE_RESET } from "../../common/redux/constants/scoreConstants";
import { listTeacherGroupClassStudents } from "../../common/redux/actions/studentsActions";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";

const ScoreEditScreen = () => {
  const { t } = useTranslation();
  const { groupId, classId, assignmentId, scoreId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [score, setScore] = useState(0);
  const [student, setStudent] = useState(0);
  const [comment, setComment] = useState("");
  const [assignment, setAssignment] = useState(assignmentId);

  const scoreDetails = useSelector((state) => state.scoreDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    scoreDetail,
  } = scoreDetails;

  const scoreUpdate = useSelector((state) => state.scoreUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = scoreUpdate;

  const teacherGroupClassStudentsList = useSelector(
    (state) => state.teacherGroupClassStudentsList
  );
  const {
    loading: loadingStudents,
    error: errorStudents,
    students,
  } = teacherGroupClassStudentsList;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: SCORE_UPDATE_RESET });
      navigate(-1);
    }
    if (!scoreDetail) {
      dispatch(detailsScore(groupId, classId, assignmentId, scoreId));
    } else {
      setStudent(scoreDetail.student);
      setScore(scoreDetail.score);
      setAssignment(scoreDetail.assignment);
      setComment(scoreDetail.comment);
    }
    dispatch(listTeacherGroupClassStudents(groupId));
  }, [
    dispatch,
    scoreDetail,
    successUpdate,
    groupId,
    navigate,
    scoreId,
    classId,
    assignmentId,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    // Update form data
    const formData = new FormData();
    formData.append("student", student);
    formData.append("assignment", assignment);
    formData.append("score", score);
    formData.append("comment", comment);
    dispatch(updateScore(groupId, classId, assignmentId, scoreId, formData));
  };

  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <div>
          <h2 className="text-[24px] font-semibold">
            Edit Assignment Score {scoreId}
          </h2>
        </div>
        <form className="form" onSubmit={submitHandler}>
          {loadingUpdate && <LoadingBox></LoadingBox>}
          {errorUpdate && (
            <MessageBox variant="danger">{errorUpdate}</MessageBox>
          )}
          {loadingDetails ? (
            <LoadingBox></LoadingBox>
          ) : errorDetails ? (
            <MessageBox variant="danger">{errorDetails}</MessageBox>
          ) : (
            <div className="mt-[30px]">
          <div className="flex flex-col pt-[10px]">
                <label htmlFor="student">{t("student")}</label>
                {loadingStudents ? (
                  <LoadingBox></LoadingBox>
                ) : errorStudents ? (
                  <MessageBox variant="danger">{errorStudents}</MessageBox>
                ) : (
                  <select
                    name="student"
                    value={student}
                    onChange={(e) => setStudent(e.target.value)}
                    placeholder="Enter Student Name"
                    className="p-[1rem]"
                    required
                  >
                    {students.map((student) => {
                      return (
                        <option value={student.id}>
                          {student.id} - {student.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="score">{t("score")}</label>
                <input
                  id="score"
                  type="number"
                  className="p-[1rem]"
                  placeholder="Enter Score"
                  required
                  value={score}
                  onChange={(e) => setScore(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="comment">{t("comment")}</label>
                <textarea
                  name="comment"
                  id="comment"
                  type="text"
                  cols="30"
                  rows="10"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>

              <div className="flex flex-col pt-[10px]">
                <button
                  className="bg-[#08AD76] text-[white] p-[1rem] "
                  type="submit"
                >
                  {t("updateScore")}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default ScoreEditScreen;
