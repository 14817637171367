

// GROUPS
export const TEACHER_GROUPS_LIST_REQUEST = "TEACHER_GROUPS_LIST_REQUEST";
export const TEACHER_GROUPS_LIST_SUCCESS = "TEACHER_GROUPS_LIST_SUCCESS";
export const TEACHER_GROUPS_LIST_FAIL = "TEACHER_GROUPS_LIST_FAIL";


export const TEACHER_GROUP_DETAILS_REQUEST = "TEACHER_GROUP_DETAILS_REQUEST";
export const TEACHER_GROUP_DETAILS_SUCCESS = "TEACHER_GROUP_DETAILS_SUCCESS";
export const TEACHER_GROUP_DETAILS_FAIL = "TEACHER_GROUP_DETAILS_FAIL";




