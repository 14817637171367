import React from "react";
import LoadingBox from "../HelperComponents/LoadingBox";
import { useTranslation } from "react-i18next";
const StatisticsBox = (props) => {
  const { t } = useTranslation();
  const {
    loadingPrimaryStatistics,
    errorPrimaryStatistics,
    statisticsPrimary,
  } = props;

  return (
    <React.Fragment>
      {loadingPrimaryStatistics ? (
        <LoadingBox></LoadingBox>
      ) : errorPrimaryStatistics ? (
        <LoadingBox></LoadingBox>
      ) : (
        <div className="grid grid-cols-3 gap-[10px] pt-[20px]">
          <div className="w-[100%] lg:h-[150px] flex flex-col p-[5px] text-[16px] lg:text-[24px] bg-[#08AD76] rounded-[10px] flex items-center justify-center text-[white]">
            <h2 className="text-[12px] lg:text-[16px] text-center">
              {t("classCount")}
            </h2>
            {statisticsPrimary.classes}
          </div>
          <div className="w-[100%]   lg:h-[150px] flex flex-col  p-[5px] text-[16px] lg:text-[24px]  bg-[#1BD2C7]  rounded-[10px] flex items-center justify-center text-[white]">
            <h2 className="text-[12px] lg:text-[16px] text-center">
              {t("present")}
            </h2>
            {statisticsPrimary.attendance.present}
          </div>
          <div className="w-[100%]  lg:h-[150px] flex flex-col  p-[5px] text-[16px] lg:text-[24px] bg-[#FFAAAA]  rounded-[10px] flex items-center justify-center text-[white]">
            <h2 className="text-[12px] lg:text-[16px]">{t("absence")}</h2>
            {statisticsPrimary.attendance.absent}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default StatisticsBox;
