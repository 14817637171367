import Axios from "axios";
import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNOUT,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
} from "../constants/userConstants";

const BASE_URL = "https://dailystat.org";

export const signin = (username, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { username, password } });
  try {
    const { data } = await Axios.post(`${BASE_URL}/account/api/token/`, {
      username,
      password,
    });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    let message = "";
    if (error.response.status === 401) {
      message = "Invalid username or password";
    } else {
      message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
    }
    dispatch({ type: USER_SIGNIN_FAIL, payload: message });
  }
};

export const signout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("userDetailsPassword");
  dispatch({ type: USER_SIGNOUT });
  document.location.href = "/login";
};

export const detailsUser = () => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.get(`${BASE_URL}/account/api/user/`, {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
  }
};

// Update user profile with form data
export const updateUserProfile = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(`${BASE_URL}/account/api/user/`, user, {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    });
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    localStorage.setItem("userDetails", JSON.stringify(data));
  } catch (error) {
    const status = error.response.status;
    let message = "";
    status === 400 ? (message = "Məlumatları düzgün yeniləyin zəhmət olmasa") : (message = "Xəta baş verdi");
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};

export const updateUserPassword = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PASSWORD_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(
      `${BASE_URL}/account/api/change_password/`,
      user,
      {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      }
    );
    dispatch({ type: USER_UPDATE_PASSWORD_SUCCESS, payload: data });
    localStorage.setItem("userDetailsPassword", JSON.stringify(data));
  } catch (error) {
    let message = "";
    if (error.response.status === 400) {
      message =
        "The password is too similar to the current password, please choose a different password";
    } else {
      message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
    }
    dispatch({ type: USER_UPDATE_PASSWORD_FAIL, payload: message });
  }
};
