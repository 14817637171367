import Axios from "axios";
import {
    TEACHER_GROUP_CLASS_STUDENTS_LIST_REQUEST,
    TEACHER_GROUP_CLASS_STUDENTS_LIST_SUCCESS,
    TEACHER_GROUP_CLASS_STUDENTS_LIST_FAIL,
} from "../constants/studentsConstants.js";
// Get Students by group id


const BASE_URL = "https://dailystat.org";

export const listTeacherGroupClassStudents =
  (id) => async (dispatch, getState) => {
    dispatch({ type: TEACHER_GROUP_CLASS_STUDENTS_LIST_REQUEST });
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await Axios.get(
        `${BASE_URL}/course/teacher/api/groups/${id}/students`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access}`,
          },
        }
      );
      dispatch({
        type: TEACHER_GROUP_CLASS_STUDENTS_LIST_SUCCESS,
        payload: data.students,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: TEACHER_GROUP_CLASS_STUDENTS_LIST_FAIL,
        payload: message,
      });
    }
  };
