import React from 'react'
import StudentClasses from '../../pages/StudentPages/StudentClasses'
const StudentAssignments = ({id}) => {
  return (
    <React.Fragment>
         <StudentClasses id={id} />
    </React.Fragment>
  )
}

export default StudentAssignments