import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { detailsUser } from "./common/redux/actions/userActions";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout/Layout";
import LoginScreen from "./pages/AuthPages/LoginScreen";
import Profile from "./pages/Profile/Profile";
import PasswordChange from "./pages/Profile/PasswordChange";
import NewsScreen from "./pages/NewsPages/NewsScreen";
import TeacherGroupRanking from "./pages/GroupPages/TeacherGroupsRanking";
import StudentDashboardPanel from "./pages/StudentPages/StudentDashboardPanel";
import NewsDetailsScreen from "./pages/NewsPages/NewsDetailsScreen";
import TeacherGroupScreen from "./pages/GroupPages/TeacherGroupsListScreen";
import TeacherClassEditScreen from "./pages/ClassPages/ClassEditScreen";
import ClassAddScreen from "./pages/ClassPages/ClassAddScreen";
import TeacherGroupDetailsScreen from "./pages/ClassPages/ClassListScreen";
import TeacherGroupStudentsScreen from "./pages/GroupPages/TeacherGroupStudentsListScreen";
import AssignmentListSceen from "./pages/AssignmentPages/AssignmentListScreen";
import AssignmentEditScreen from "./pages/AssignmentPages/AssignmentEditScreen";
import AssignmentAddScreen from "./pages/AssignmentPages/AssignmentAddScreen";
import AttendanceListScreen from "./pages/AttendancePages/AttendanceListScreen";
import AttendanceAddScreen from "./pages/AttendancePages/AttendanceAddScreen";
import AttendanceEditScreen from "./pages/AttendancePages/AttendanceEditScreen";
import ScoreListScreen from "./pages/ScorePages/ScoreListScreen";
import ScoreAddScreen from "./pages/ScorePages/ScoreAddScreen";
import ScoreEditScreen from "./pages/ScorePages/ScoreEditScreen";
import PageNotFound from "./components/FeatureComponents/PageNotFound";
import StudentAssignments from "./components/StudentComponents/StudentAssignments";
import Contact from "./components/HelperComponents/Contact";
import Payment from "./components/HelperComponents/Payment";
const App = () => {
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    dispatch(detailsUser());
  }, [dispatch]);



  const [photo, setPhoto] = useState(null);
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const setPhotoHandler = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
  
    // Create a FileReader instance
    const reader = new FileReader();
  
    // Set a callback function when the file is loaded
    reader.onload = () => {
      setPreviewPhoto(reader.result);
    };
  
    // Read the file as a data URL
    reader.readAsDataURL(file);
  };



  return (
    <Router>
      <Routes>
        {userInfo ? (
          <Route path="/" element={<Layout photo={photo} setPhotoHandler={setPhotoHandler} setPhoto={setPhoto} previewPhoto={previewPhoto} />}>
            {user?.type === "teacher" && (
              <Route index element={<TeacherGroupScreen />} />
            )}
            {user?.type === "student" && (
              <Route index element={<StudentDashboardPanel user={user} />} />
            )}
            <Route path="/news" element={<NewsScreen />} />
            <Route
              path="/news/:slug"
              element={<NewsDetailsScreen />}
              exact
            ></Route>
            <Route path="/profile" element={<Profile photo={photo} setPhotoHandler={setPhotoHandler} setPhoto={setPhoto} previewPhoto={previewPhoto} />} />
            <Route path="change_password" element={<PasswordChange />} />
            {/* Student Routes */}
            <Route path="/contact" element={<Contact />} exact />
            <Route path="/payment" element={<Payment />} exact />
            <Route path="/assignments" element={<StudentAssignments />} exact />

            {/* Teacher Routes */}
            <Route
              path="/teacher/groups"
              element={<TeacherGroupScreen />}
              exact
            />
              <Route
              path="/teacher/group/:id"
              element={<TeacherGroupDetailsScreen />}
              exact
            />
            <Route
              path="/teacher/group/:groupId/class/:classId/edit"
              element={<TeacherClassEditScreen />}
              exact
            />
            <Route
              path="/teacher/group/:groupId/createclass"
              element={<ClassAddScreen />}
            />
            <Route
              path="/teacher/group/:id/students"
              element={<TeacherGroupStudentsScreen />}
            />
            <Route
              path="/teacher/group/:id/ranking"
              element={<TeacherGroupRanking />}
            />

            <Route
              path="/teacher/group/:groupId/class/:classId/assignments"
              element={<AssignmentListSceen />}
              exact
            />
            <Route
              path="/teacher/group/:groupId/class/:classId/assignments/:assignmentId/edit"
              element={<AssignmentEditScreen />}
              exact
            />
            <Route
              path="/teacher/group/:groupId/class/:classId/createassignment"
              element={<AssignmentAddScreen />}
            />

            {/* Attendance */}
            <Route
              path="/teacher/group/:groupId/class/:classId/attendances"
              element={<AttendanceListScreen />}
              exact
            />
            <Route
              path="/teacher/group/:groupId/class/:classId/attendance/create"
              element={<AttendanceAddScreen />}
              exact
            />
            <Route
              path="/teacher/group/:groupId/class/:classId/attendance/:attendanceId/edit"
              element={<AttendanceEditScreen />}
              exact
            />

            <Route
              path="/teacher/group/:groupId/class/:classId/assignments/:assignmentId/scores"
              element={<ScoreListScreen />}
              exact
            />
            <Route
              path="/teacher/group/:groupId/class/:classId/assignments/:assignmentId/score/create"
              element={<ScoreAddScreen />}
              exact
            />
            <Route
              path="/teacher/group/:groupId/class/:classId/assignments/:assignmentId/scores/:scoreId/edit"
              element={<ScoreEditScreen />}
              exact
            />

            <Route path="*" element={PageNotFound} />
          </Route>
        ) : (
          <Route path="/login" element={<LoginScreen />} />
        )}
        <Route path="/login" element={<LoginScreen />} />
        <Route path="*" element={PageNotFound} />
      </Routes>
    </Router>
  );
};

export default App;
