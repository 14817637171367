import {
  STUDENT_PRIMARY_STATISTICS_LIST_FAIL,
  STUDENT_PRIMARY_STATISTICS_LIST_REQUEST,
  STUDENT_PRIMARY_STATISTICS_LIST_SUCCESS,
  STUDENT_SECONDARY_STATISTICS_LIST_FAIL,
  STUDENT_SECONDARY_STATISTICS_LIST_REQUEST,
  STUDENT_SECONDARY_STATISTICS_LIST_SUCCESS,
  TEACHER_STATISTICS_LIST_FAIL,
  TEACHER_STATISTICS_LIST_REQUEST,
  TEACHER_STATISTICS_LIST_SUCCESS,
} from "../constants/statisticsConstants";

export const studentPrimaryStatisticsListReducer = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case STUDENT_PRIMARY_STATISTICS_LIST_REQUEST:
      return { loading: true };
    case STUDENT_PRIMARY_STATISTICS_LIST_SUCCESS:
      return { loading: false, statisticsPrimary: action.payload };
    case STUDENT_PRIMARY_STATISTICS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const studentSecondaryStatisticsListReducer = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case STUDENT_SECONDARY_STATISTICS_LIST_REQUEST:
      return { loading: true };
    case STUDENT_SECONDARY_STATISTICS_LIST_SUCCESS:
      return { loading: false, statisticsSecondary: action.payload };
    case STUDENT_SECONDARY_STATISTICS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teacherStatisticsListReducer = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case TEACHER_STATISTICS_LIST_REQUEST:
      return { loading: true };
    case TEACHER_STATISTICS_LIST_SUCCESS:
      return { loading: false, teacherStatistics: action.payload };
    case TEACHER_STATISTICS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

