import React from "react";
import { Link } from "react-router-dom";
const PageNotFound = () => {
  return (
    <React.Fragment>
      <div>
        <h1>Come back to home page</h1>
        <Link to="/dashboard">Go to home page</Link>
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;
