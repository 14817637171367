import {
  ATTENDANCE_STATUS_LIST_REQUEST,
  ATTENDANCE_STATUS_LIST_SUCCESS,
  ATTENDANCE_STATUS_LIST_FAIL,
  ATTENDANCE_LIST_REQUEST,
  ATTENDANCE_LIST_SUCCESS,
  ATTENDANCE_LIST_FAIL,
  ATTENDANCE_DELETE_FAIL,
  ATTENDANCE_DELETE_REQUEST,
  ATTENDANCE_DELETE_SUCCESS,
  ATTENDANCE_CREATE_FAIL,
  ATTENDANCE_CREATE_REQUEST,
  ATTENDANCE_CREATE_SUCCESS,
  ATTENDANCE_UPDATE_FAIL,
  ATTENDANCE_UPDATE_REQUEST,
  ATTENDANCE_UPDATE_SUCCESS,
  ATTENDANCE_DETAILS_FAIL,
  ATTENDANCE_DETAILS_REQUEST,
  ATTENDANCE_DETAILS_SUCCESS,
} from "../constants/attendanceConstants";
// Attendance Actions
import Axios from "axios";



const BASE_URL = "https://dailystat.org";


export const listAttendanceStatus = () => async (dispatch, getState) => {
  dispatch({ type: ATTENDANCE_STATUS_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(`${BASE_URL}/course/teacher/api/attendance/status/`, {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    });
    dispatch({ type: ATTENDANCE_STATUS_LIST_SUCCESS, payload: data });
  
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ATTENDANCE_STATUS_LIST_FAIL, payload: message });
  }
};



export const listAttendances = (groupID, classID) => async (dispatch, getState) => {
  dispatch({ type: ATTENDANCE_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(
      `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classID}/attendance/`,
      {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      }
    );
    dispatch({
      type: ATTENDANCE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ATTENDANCE_LIST_FAIL,
      payload: message,
    });
  }
};





export const createAttendance = (groupId, classId, attendance) => async (dispatch, getState) => {
  dispatch({ type: ATTENDANCE_CREATE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.post(
      `${BASE_URL}/course/teacher/api/groups/${groupId}/classes/${classId}/attendance/`,
    attendance,
      {
        // Handle 401 error
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      }
    );
    dispatch({
      type: ATTENDANCE_CREATE_SUCCESS,
      payload: data.attendance,
    });
  } catch (error) {
    const status = error.response.status;
    let message = "";
    status === 400 ? (message = "Hər bir tələbənin bir davamiyyəti ola bilər") : (message = "Xəta baş verdi");
    dispatch({ type: ATTENDANCE_CREATE_FAIL, payload: message });
  }
};



export const detailsAttendance =
  (groupID, classID, attendanceId) => async (dispatch, getState) => {
    dispatch({ type: ATTENDANCE_DETAILS_REQUEST });
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await Axios.get(
        `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classID}/attendance/${attendanceId}/`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access}`,
          },
        }
      );
      dispatch({
        type: ATTENDANCE_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ATTENDANCE_DETAILS_FAIL,
        payload: message,
      });
    }
  };

export const updateAttendance =
  (groupID, classId, attendance) => async (dispatch, getState) => {
    dispatch({
      type: ATTENDANCE_UPDATE_REQUEST,
      payload: attendance,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.patch(
        `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classId}/attendance/`,
        attendance,
        {
          headers: { Authorization: `Bearer ${userInfo.access}` },
        }
      );
      dispatch({
        type: ATTENDANCE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ATTENDANCE_UPDATE_FAIL,
        error: message,
      });
    }
  };







export const deleteAttendance = (groupID, classID, student) => async (dispatch, getState) => {
  dispatch({ type: ATTENDANCE_DELETE_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.delete(
      `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classID}/attendance/`,
      {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
        data: {
          student: student,
        },
      }
    );
    dispatch({ type: ATTENDANCE_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ATTENDANCE_DELETE_FAIL, payload: message });
  }
};