import {
    TEACHER_GROUP_CLASS_CREATE_REQUEST,
    TEACHER_GROUP_CLASS_CREATE_SUCCESS,
    TEACHER_GROUP_CLASS_CREATE_FAIL,
    TEACHER_GROUP_CLASS_CREATE_RESET,
    TEACHER_GROUP_CLASS_DETAILS_REQUEST,
    TEACHER_GROUP_CLASS_DETAILS_SUCCESS,
    TEACHER_GROUP_CLASS_DETAILS_FAIL,
    TEACHER_GROUP_CLASS_UPDATE_REQUEST,
    TEACHER_GROUP_CLASS_UPDATE_SUCCESS,
    TEACHER_GROUP_CLASS_UPDATE_FAIL,
    TEACHER_GROUP_CLASS_DELETE_REQUEST,
    TEACHER_GROUP_CLASS_DELETE_SUCCESS,
    TEACHER_GROUP_CLASS_DELETE_FAIL,
    TEACHER_GROUP_CLASS_UPDATE_RESET,
    TEACHER_GROUP_CLASS_DELETE_RESET,
    } from "../constants/classConstants";


export const teacherGroupClassCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case TEACHER_GROUP_CLASS_CREATE_REQUEST:
        return { loading: true };
      case TEACHER_GROUP_CLASS_CREATE_SUCCESS:
        return { loading: false, success: true, groupClass: action.payload };
      case TEACHER_GROUP_CLASS_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case TEACHER_GROUP_CLASS_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const teacherGroupClassDetailsReducer = (
    state = { loading: true },
    action
  ) => {
    switch (action.type) {
      case TEACHER_GROUP_CLASS_DETAILS_REQUEST:
        return { loading: true };
      case TEACHER_GROUP_CLASS_DETAILS_SUCCESS:
        return { loading: false, groupClass: action.payload };
      case TEACHER_GROUP_CLASS_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  

 export const teacherGroupClassUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case TEACHER_GROUP_CLASS_UPDATE_REQUEST:
        return { loading: true };
      case TEACHER_GROUP_CLASS_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case TEACHER_GROUP_CLASS_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case TEACHER_GROUP_CLASS_UPDATE_RESET:
        return {};
      default:
        return state;
    }
  };

  export const teacherGroupClassDeleteReducer = (state = 
    { groupClass: [] }
    , action) => {  
    switch (action.type) {
      case TEACHER_GROUP_CLASS_DELETE_REQUEST:
        return { loading: true };
      case TEACHER_GROUP_CLASS_DELETE_SUCCESS:
        // Filter out the deleted class from the state array
        return {
          loading: false,
          success: true,
          classes:
          state.groupClass &&
          state.groupClass.filter((c) => c.id !== action.payload.classId),
      };
      case TEACHER_GROUP_CLASS_DELETE_FAIL:
        return { loading: false, error: action.payload };
      case TEACHER_GROUP_CLASS_DELETE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  
  
  
  
  
  
  
  
  
  
  