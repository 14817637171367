import React from "react";
import LoadingBox from "../HelperComponents/LoadingBox";
const Statistics = (props) => {
  const {
    statisticsSecondary,
    loadingSecondaryStatistics,
    errorSecondaryStatistics,
    user,
  } = props;

  const nameCombine = user?.first_name + " " + user?.last_name;
  
  const a = statisticsSecondary?.findIndex(
    (x) => x.student_name === nameCombine
  );

  return (
    <React.Fragment>
      <div className="flex items-center justify-between flex-col lg:flex-row  mt-[20px]">
        <div className="w-[100%] lg:w-[15%] lg:h-[230px] h-[100%]  rounded-[10px] p-[15px] bg-[#222631] text-[white]">
          <h2 className="text-[16px] lg:text-[24px] font-bold">Rating</h2>
          <div className="w-[100%] flex justify-center items-center flex-col pt-[10px]  lg:pt-[30px]">
            <h3 className="text-[24px] font-bold lg:text-[32px]">{a + 1}</h3>
            <p className="font-bold pt-[10px]">place in the group</p>
          </div>
        </div>
        <div className="w-[100%] lg:w-[85%] h-[230px] mt-[20px] lg:mt-[0px] lg:ml-[20px] rounded-[10px] p-[15px] bg-[#222631] text-[white] ">
          <h2 className="text-[16px] lg:text-[24px] font-bold">
            Board of Leaders
          </h2>
          {loadingSecondaryStatistics ? (
            <LoadingBox></LoadingBox>
          ) : errorSecondaryStatistics ? (
            <LoadingBox></LoadingBox>
          ) : (
            <div className="min-h-[150px] max-h-[150px] bg-[#303440] mt-[10px] rounded-[10px] p-[10px] overflow-y-scroll">
              {statisticsSecondary.map((ranking) => {
                return (
                  <div className="flex justify-between items-center p-[10px]">
                    <div className="text-[16px]">{ranking.student_name}</div>
                    <div>{ranking.total_score}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Statistics;
