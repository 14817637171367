import Axios from "axios";
import {
  ENROLLMENT_DETAILS_REQUEST,
  ENROLLMENT_DETAILS_SUCCESS,
  ENROLLMENT_DETAILS_FAIL,
} from "../constants/enrollmentConstants";


const BASE_URL = "https://dailystat.org";

export const detailsEnrollment = () => async (dispatch, getState) => {
  dispatch({ type: ENROLLMENT_DETAILS_REQUEST});
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(`${BASE_URL}/course/student/api/my-enrollment`, {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    });
    dispatch({ type: ENROLLMENT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ENROLLMENT_DETAILS_FAIL, payload: message });
  }
};
