import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { detailsTeacherGroup } from "../../common/redux/actions/groupActions";
import { deleteTeacherGroupClass } from "../../common/redux/actions/classActions";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { TEACHER_GROUP_CLASS_DELETE_RESET } from "../../common/redux/constants/classConstants";
import { useNavigate, Link, useParams } from "react-router-dom";
import moment from "moment";
import ComeBack from "../../components/HelperComponents/ComeBack";

import { useTranslation } from "react-i18next";
const TeacherGroupDetailsScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const teacherGroupDetails = useSelector((state) => state.teacherGroupDetails);
  const { loading, error, group } = teacherGroupDetails;
  const teacherGroupClassDelete = useSelector(
    (state) => state.teacherGroupClassDelete
  );
  const {
    loading: loadingDeleteClass,
    error: errorDeleteClass,
    success: successDeleteClass,
  } = teacherGroupClassDelete;

  const deleteHandler = (it, item) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (confirmDelete) {
      dispatch(deleteTeacherGroupClass(it, item?.id));
    }
  };

  useEffect(() => {
    if (successDeleteClass) {
      dispatch({ type: TEACHER_GROUP_CLASS_DELETE_RESET });
      alert("Attendance deleted successfully!");
    }
    dispatch(detailsTeacherGroup(id));
  }, [dispatch, successDeleteClass, id]);


  


  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <h2 className="mt-[15px] font-bold text-[28px]">{t("classList")}</h2>
        <div className="flex">
          <div className="w-[200px] mt-[10px] bg-[#1365cf] p-[10px] text-[white] text-center rounded-[10px]">
            <Link to={`/teacher/group/${id}/students`}>
              {t("viewStudents")}
            </Link>
          </div>
          <Link to={`/teacher/group/${id}/createclass`}>
            <div className="w- [200px] mt-[10px] ml-[10px] rounded-[10px] bg-[#08AD76] p-[10px] text-[white] text-center cursor-pointer">
              {t("addClass")}
            </div>
          </Link>
          <Link to={`/teacher/group/${id}/ranking`}>
            <div className="w-[200px] mt-[10px] ml-[10px] rounded-[10px] bg-[#f68300] p-[10px] text-[white] text-center cursor-pointer">
              {t("viewRanking")}
            </div>
          </Link>
        </div>

        {loadingDeleteClass && <LoadingBox></LoadingBox>}
        {errorDeleteClass && (
          <MessageBox variant="danger">{errorDeleteClass}</MessageBox>
        )}
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div className="flex flex-col mt-[30px]">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-16">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full" id="userTable">
                    <thead className="bg-white border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                        ID
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                          N#
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                          {t("name")}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                          {t("startDate")}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                          {t("endDate")}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                        >
                          {t("actions")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {group.map((item, i) => {
                         const startDate = moment(item?.start_date).format("DD MMMM YYYY HH:mm");
                         const endDate = moment(item?.end_date).format("DD MMMM YYYY HH:mm");
                        return (
                          <tr className="bg-gray-100 border-b">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {item?.id}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {i + 1}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {item?.class_title}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {startDate}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {endDate}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              <div>
                                <button
                                  className="bg-[#08AD76] p-[10px] text-white m-[5px] rounded-[5px]"
                                  onClick={() =>
                                    navigate(
                                      `/teacher/group/${id}/class/${item?.id}/edit`
                                    )
                                  }
                                >
                                  {t("edit")}
                                </button>
                                <button
                                  className="bg-[#dc3545] p-[10px] text-white m-[5px] rounded-[5px]"
                                  onClick={() => deleteHandler(id, item)}
                                >
                                  {t("delete")}
                                </button>
                                <button className="bg-[#1365cf] p-[10px] text-white m-[5px] rounded-[5px]">
                                  <Link
                                    to={`/teacher/group/${id}/class/${item.id}/assignments`}
                                  >
                                    {t("assignments")}
                                  </Link>
                                </button>
                                <button className="bg-[#f68300] p-[10px] text-white m-[5px] rounded-[5px]">
                                  <Link
                                    to={`/teacher/group/${id}/class/${item.id}/attendances`}
                                  >
                                    {t("attendances")}
                                  </Link>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TeacherGroupDetailsScreen;
