export const ASSIGNMENTS_LIST_REQUEST = "ASSIGNMENTS_LIST_REQUEST";
export const ASSIGNMENTS_LIST_SUCCESS = "ASSIGNMENTS_LIST_SUCCESS";
export const ASSIGNMENTS_LIST_FAIL = "ASSIGNMENTS_LIST_FAIL";

export const ASSIGNMENT_DETAILS_REQUEST = "ASSIGNMENT_DETAILS_REQUEST";
export const ASSIGNMENT_DETAILS_SUCCESS = "ASSIGNMENT_DETAILS_SUCCESS";
export const ASSIGNMENT_DETAILS_FAIL = "ASSIGNMENT_DETAILS_FAIL";

export const ASSIGNMENT_CREATE_REQUEST = "ASSIGNMENT_CREATE_REQUEST";
export const ASSIGNMENT_CREATE_SUCCESS = "ASSIGNMENT_CREATE_SUCCESS";
export const ASSIGNMENT_CREATE_FAIL = "ASSIGNMENT_CREATE_FAIL";
export const ASSIGNMENT_CREATE_RESET = "ASSIGNMENT_CREATE_RESET";

export const ASSIGNMENT_UPDATE_REQUEST = "ASSIGNMENT_UPDATE_REQUEST";
export const ASSIGNMENT_UPDATE_SUCCESS = "ASSIGNMENT_UPDATE_SUCCESS";
export const ASSIGNMENT_UPDATE_FAIL = "ASSIGNMENT_UPDATE_FAIL";
export const ASSIGNMENT_UPDATE_RESET = "ASSIGNMENT_UPDATE_RESET";

export const ASSIGNMENT_DELETE_REQUEST = "ASSIGNMENT_DELETE_REQUEST";
export const ASSIGNMENT_DELETE_SUCCESS = "ASSIGNMENT_DELETE_SUCCESS";
export const ASSIGNMENT_DELETE_FAIL = "ASSIGNMENT_DELETE_FAIL";
export const ASSIGNMENT_DELETE_RESET = "ASSIGNMENT_DELETE_RESET";
