import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../components/FeatureComponents/SideBar";
import Header from "../../components/FeatureComponents/Header";
import { useDispatch, useSelector } from "react-redux";
import { detailsUser } from "../../common/redux/actions/userActions";
const Layout = ({photo, setPhoto, previewPhoto, setPhotoHandler}) => {
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const userDetails = useSelector((state) => state.userDetails);
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    user,
  } = userDetails;

  


  useEffect(() => {
    dispatch(detailsUser(userInfo.access));
  }, [dispatch, userInfo.access]);
  

  if (!userInfo) {
    window.location.href = "/login";
  }
  return (
    <div className="bg-neutral-100 w-[100%] h-screen w-screen overflow-hidden flex flex-row lg:p-[15px]">
      <SideBar user={user} loadingUserDetails={loadingUserDetails} errorUserDetails={errorUserDetails}/>
      <div className="flex flex-col flex-1 pl-[5px] lg:pl-[15px]">
        <Header previewPhoto={previewPhoto} />
        <div className="flex-1 p-2 lg:p-4 min-h-0 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
