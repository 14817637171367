import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detailsAssignment,
  updateAssignment,
} from "../../common/redux/actions/assignmentActions";
import { ASSIGNMENT_UPDATE_RESET } from "../../common/redux/constants/assignmentConstants";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";

const AssignmentEditScreen = () => {
  const { t } = useTranslation();
  const { groupId, classId, assignmentId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");

  const assignmentDetails = useSelector((state) => state.assignmentDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    assignment,
  } = assignmentDetails;
  
  const assignmentUpdate = useSelector((state) => state.assignmentUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = assignmentUpdate;
  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: ASSIGNMENT_UPDATE_RESET });
      navigate(-1);
    }
    if (!assignment) {
      dispatch(detailsAssignment(groupId, classId, assignmentId));
    } else {
      setTitle(assignment.title);
      setDescription(assignment.description);
      setFile(assignment.file);
    }
  }, [dispatch, assignment,  successUpdate, groupId, navigate, classId, assignmentId]);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("file", file);
    dispatch(updateAssignment(groupId, classId, assignmentId,formData));
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(
  //     updateAssignment(groupId, classId, assignmentId, {
  //       title,
  //       description,
  //       file,
  //     })
  //   )
  // }

  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <div>
          <h2 className="text-[24px] font-semibold">
            Edit Assignment {assignmentId}
          </h2>
        </div>
        <form className="form" onSubmit={submitHandler}>
          {loadingUpdate && <LoadingBox></LoadingBox>}
          {errorUpdate && (
            <MessageBox variant="danger">{errorUpdate}</MessageBox>
          )}
          {loadingDetails ? (
            <LoadingBox></LoadingBox>
          ) : errorDetails ? (
            <MessageBox variant="danger">{errorDetails}</MessageBox>
          ) : (
            <div className="mt-[30px]">
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="title">{t("title")}</label>
                <input
                  id="title"
                  type="text"
                  className="p-[1rem]"
                  
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="description">{t("description")}</label>
                <input
                  id="description"
                  type="text"
                  className="p-[1rem]"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="file">{t("file")}</label>
                <input
                  id="file"
                  type="file"
                  className="p-[1rem]"
                  placeholder="Enter File"
                  onChange={(e) => setFile(e.target.files[0])}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <button
                  className="bg-[#08AD76] text-[white] p-[1rem] "
                  type="submit"
                >
                  {t("updateAssignment")}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default AssignmentEditScreen;
