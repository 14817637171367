import { useTranslation } from "react-i18next";

const lngs = [
    //Aze
  { code: "az", native: "az" },  
  { code: "en", native: "en" },
  { code: "ru", native: "ru" },
];

export default function App() {
    const { i18n } = useTranslation();

  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };

  return (
    <div>
      {lngs.map((lng, i) => {
        const { code, native } = lng;
        return <button className="p-[10px] font-semibold" onClick={() => handleTrans(code)}>{native}</button>;
      })}

    </div>
  );
}