
import {
    TEACHER_GROUP_CLASS_STUDENTS_LIST_REQUEST,
    TEACHER_GROUP_CLASS_STUDENTS_LIST_SUCCESS,
    TEACHER_GROUP_CLASS_STUDENTS_LIST_FAIL,
} from "../constants/studentsConstants";

// Students

export const teacherGroupClassStudentsListReducer = (
    state = { loading: true},
    action
  ) => {
    switch (action.type) {
      case TEACHER_GROUP_CLASS_STUDENTS_LIST_REQUEST:
        return { loading: true };
      case TEACHER_GROUP_CLASS_STUDENTS_LIST_SUCCESS:
        return { loading: false, students: action.payload };
      case TEACHER_GROUP_CLASS_STUDENTS_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };