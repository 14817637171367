import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { listTeacherGroupClassStudents } from "../../common/redux/actions/studentsActions";
import { SCORE_CREATE_RESET } from "../../common/redux/constants/scoreConstants";
import { createScore } from "../../common/redux/actions/scoreActions";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";

const ScoreAddScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupId, classId, assignmentId } = useParams();
  const navigate = useNavigate();
  const [student, setStudent] = useState(0);
  const [score, setScore] = useState(0);
  const [comment, setComment] = useState("");
  const [assignment] = useState(assignmentId);
  
  const scoreCreate = useSelector((state) => state.scoreCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    assignmentScore,
  } = scoreCreate;

  const teacherGroupClassStudentsList = useSelector(
    (state) => state.teacherGroupClassStudentsList
  );
  const { loading, error, students } = teacherGroupClassStudentsList;
  useEffect(() => {
    if (successCreate) {
      dispatch({ type: SCORE_CREATE_RESET });
      navigate(-1);
    }
    dispatch(listTeacherGroupClassStudents(groupId));
  }, [
    dispatch,
    assignmentScore,
    successCreate,
    groupId,
    assignmentId,
    navigate,
    classId,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("student", student);
    formData.append("score", score);
    formData.append("assignment", assignment);
    formData.append("comment", comment);
    dispatch(createScore(groupId, classId, assignmentId, formData));
  };


  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <form className="form" onSubmit={submitHandler}>
          <div>
            <h2 className="text-[24px] font-semibold">
              Create Class for Group Class Assignment Score
            </h2>
          </div>
          {loadingCreate ? (
            <LoadingBox></LoadingBox>
          ) : errorCreate ? (
            <MessageBox variant="danger">{errorCreate}</MessageBox>
          ) : (
            <div className="mt-[30px]">
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="student">{t("student")}</label>
                {loading ? (
                  <LoadingBox></LoadingBox>
                ) : error ? (
                  <MessageBox variant="danger">{error}</MessageBox>
                ) : (
                  <select
                    name="student"
                    value={student}
                    onChange={(e) => setStudent(e.target.value)}
                    placeholder="Enter Student Name"
                    className="p-[1rem]"
                    required
                  >
                    <option value="" >
                      Tələbə seçin zəhmət olmasa
                    </option>
                    {students.map((student) => {
                      return (
                        <option value={student.id} selected={student.id}>
                          {student.id} - {student.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="score">{t("score")}</label>
                <input
                  id="score"
                  type="number"
                  className="p-[1rem]"
                  placeholder="Enter Score"
                  required
                  min="0"
                  value={score}
                  onChange={(e) => setScore(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="comment">{t("comment")}</label>
                <textarea
                  name="comment"
                  id="comment"
                  type="text"
                  cols="30"
                  rows="10"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>

              <div className="flex flex-col pt-[10px]">
                <button
                  className="bg-[#08AD76] text-[white] p-[1rem] "
                  type="submit"
                >
                  Crete Assignment Score
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default ScoreAddScreen;
