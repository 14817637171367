import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserPassword } from "../../common/redux/actions/userActions";
import { USER_UPDATE_PASSWORD_RESET } from "../../common/redux/constants/userConstants";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";
const PasswordChange = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userUpdatePassword = useSelector((state) => state.userUpdatePassword);
  const {
    success: successUpdatePassword,
    error: errorUpdatePassword,
    loading: loadingUpdatePassword,
  } = userUpdatePassword;

  const [old_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  useEffect(() => {
    if (!user) {
      dispatch({ type: USER_UPDATE_PASSWORD_RESET });
    } else {
      setOldPassword(user.old_password);
      setPassword(user.password);
      setPassword2(user.password2);
    }
  }, [dispatch, userInfo.access, user]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password.length < 6) {
      alert("Password must be at least 6 characters");
    }
    if (password !== password2) {
      alert("Password and Confirm Password Are Not Matched");
    }
    // dispatch update profile
    dispatch(
      updateUserPassword({
        old_password,
        password,
        password2,
      })
    );
  };

  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <form onSubmit={submitHandler}>
          <div>
            {errorUpdatePassword && (
              <MessageBox variant="danger">{errorUpdatePassword}</MessageBox>
            )}
            {loadingUpdatePassword ? (
              <LoadingBox></LoadingBox>
            ) : (
              <>
                {successUpdatePassword && (
                  <MessageBox variant="success">
                    Password changed Successfully
                  </MessageBox>
                )}
                <div>
                  <div className="flex flex-col pt-[10px]">
                    <label>{t("oldPassword")}</label>
                    <input
                      type="password"
                      placeholder={t("oldPassword")}
                      value={old_password}
                      onChange={(e) => setOldPassword(e.target.value)}
                      className="mt-[5px] rounded-[5px] p-[10px] shadow border-[1px] border-solid border-[#232631]"
                    />
                  </div>
                  <div className="flex flex-col pt-[10px]">
                    <label>{t("newPassword")}</label>
                    <input
                      type="password"
                      placeholder={t("newPassword")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="mt-[5px] rounded-[5px] p-[10px] shadow border-[1px] border-solid border-[#232631]"
                    />
                  </div>
                  <div className="flex flex-col pt-[10px]">
                    <label>{t("confirmPassword")}</label>
                    <input
                      type="password"
                      placeholder={t("confirmPassword")}
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                      className="mt-[5px] rounded-[5px] p-[10px] shadow border-[1px] border-solid border-[#234631]"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className=" w-[100%] bg-[#232631] text-white text-center py-[10px] mt-[15px]">
            <button className="w-[100%]" type="submit">
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default PasswordChange;
