import React from "react";
import { Link } from "react-router-dom";
import { TbArrowUpRight } from "react-icons/tb";
import { useTranslation } from "react-i18next";
const GroupBox = (props) => {
  const {t} = useTranslation();
  const { group } = props;
  return (
    <React.Fragment>
       <div className="shadow w-[100%] lg:w-[350px] p-[15px] rounded-[10px] relative bg-[white] mt-[10px]">
      <Link to={`/teacher/group/${group.id}`}>
          <h2 className="text-[24px] font-semibold">{group.name}</h2>
          <p className="text-[16px]">{group.description}</p>
          <div className="pt-[10px]">
            <h3>
              {t("startDate")} -{" "}
              <span className="font-semibold">{group.start_date}</span>
            </h3>
            <h3>
              {t("endDate")} - <span className="font-semibold">{group.end_date}</span>
            </h3>
          </div>
          <div className="absolute top-[10%] right-[10%] font-bold text-[24px]">
            <TbArrowUpRight />
          </div>
      </Link>
      </div>
    </React.Fragment>
  );
};

export default GroupBox;
