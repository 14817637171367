import React, { useEffect } from "react";
import { useState } from "react";
import moment from 'moment';
const AbsenceBox = (props) => {
  const { groupClass, enrollment } = props;
  const [bgColor, setBgColor] = useState("");
  useEffect(() => {
    if (groupClass.attendance === "Absent") {
      setBgColor("#FFAAAA");
    } else if (groupClass.attendance === "Present") {
      setBgColor("#F1F6FA");
    } else {
      setBgColor("#ffc730");
    }
  }, [groupClass.attendance]);
  const formattedDate = moment(groupClass.start_date).format('MMMM Do'); // format the date

  return (
    <React.Fragment>
      <div
        className={`bg-[${bgColor}] w-[100px] h-[100px]  rounded-[5px] cursor-pointer mt-[10px] relative`}
      >
        <p className="text-center text-[12px] pt-[15px]">
          {formattedDate}
        </p>
        <div className="flex items-center justify-center font-semibold text-[20px] pt-[10px]">
          {groupClass.class_number}
        </div>
        {groupClass.average_score !== null ? (
               <div
               className={`${
                 groupClass.attendance === "Absent"
                   ? "hidden"
                   : "absolute right-[2%] bottom-[2%] bg-[#ffc730] text-[white] rounded-[15px] p-[5px] text-[12px]"
               }`}
             >
               {Math.round(groupClass.average_score)}
             </div>
        ) : (
          <div className="absolute right-[2%] text-[black] bottom-[2%] text-[white] rounded-[15px] p-[5px] text-[16px]">
            -
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AbsenceBox;
