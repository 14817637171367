import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { detailsUser } from "../../common/redux/actions/userActions";
import MultiLanguage from "../../utils/MultiLanguage/MultiLanguage";
import { Link } from "react-router-dom";
const Header = ({previewPhoto}) => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(detailsUser(userInfo.access));
  }, [dispatch, userInfo.access]);

  return (
    <React.Fragment>
      <div className="w-[100%] flex items-center justify-between  p-[15px]">
        <div>
          <MultiLanguage />
        </div>
        <div>
          <div className="flex items-center">
            <div className="mr-[10px]">
              <div className="text-[14px] font-bold">
                {user?.first_name} {user?.last_name}
              </div>
              <div className="text-[12px] font-semibold text-right">
                {user?.type}
              </div>
            </div>
            <Link to="/profile">
              <div className="w-[40px] h-[40px] rounded-full bg-[#222631] flex items-center justify-center text-[white]">
                {user?.photo ? (
                  <img
                    className="rounded-full w-[100%] h-[100%]"
                    src={previewPhoto || user.photo}
                    alt=""
                  />
                ) : (
                  <div>
                    {user?.first_name[0]}
                    {user?.last_name[0]}
                  </div>
                )}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
