
import {
    SCORES_LIST_REQUEST,
    SCORES_LIST_SUCCESS,
    SCORES_LIST_FAIL,
    SCORE_CREATE_REQUEST,
    SCORE_CREATE_SUCCESS,
    SCORE_CREATE_FAIL,
    SCORE_CREATE_RESET,
    SCORE_DETAILS_REQUEST,
    SCORE_DETAILS_SUCCESS,
    SCORE_DETAILS_FAIL,
    SCORE_UPDATE_REQUEST,
    SCORE_UPDATE_SUCCESS,
    SCORE_UPDATE_FAIL,
    SCORE_UPDATE_RESET,
    SCORE_DELETE_REQUEST,
    SCORE_DELETE_SUCCESS,
    SCORE_DELETE_FAIL,
    SCORE_DELETE_RESET,
    } from "../constants/scoreConstants";
// Assignments

export const scoresListReducer = (
    state = { loading: true},
    action
  ) => {
    switch (action.type) {
      case SCORES_LIST_REQUEST:
        return { loading: true };
      case SCORES_LIST_SUCCESS:
        return { loading: false, scores: action.payload };
      case SCORES_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  
  
  export const scoreCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case SCORE_CREATE_REQUEST:
        return { loading: true };
      case SCORE_CREATE_SUCCESS:
        return { loading: false, success: true, assignmentScore: action.payload };
      case SCORE_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case SCORE_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const scoreDetailsReducer = (
    state = { loading: true },
    action
  ) => {
    switch (action.type) {
      case SCORE_DETAILS_REQUEST:
        return { loading: true };
      case SCORE_DETAILS_SUCCESS:
        return { loading: false, scoreDetail: action.payload };
      case SCORE_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const scoreUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case SCORE_UPDATE_REQUEST:
        return { loading: true };
      case SCORE_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case SCORE_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case SCORE_UPDATE_RESET:
        return {};
      default:
        return state;
    }
  };
  export const scoreDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case SCORE_DELETE_REQUEST:
        return { loading: true };
      case SCORE_DELETE_SUCCESS:
        return { loading: false, success: true };
      case SCORE_DELETE_FAIL:
        return { loading: false, error: action.payload };
      case SCORE_DELETE_RESET:
        return {};
      default:
        return state;
    }
  };