import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detailsTeacherGroupClass,
  updateTeacherGroupClass,
} from "../../common/redux/actions/classActions";
import { TEACHER_GROUP_CLASS_UPDATE_RESET } from "../../common/redux/constants/classConstants";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";

const TeacherClassEditScreen = () => {
  const { t } = useTranslation();
  const { groupId, classId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [class_number, setClassNumber] = useState("");
  const [class_title, setClassTitle] = useState("");
  const [end_date, setEndDate] = useState("");
  const [start_date, setStartDate] = useState("");

  const teacherGroupClassDetails = useSelector(
    (state) => state.teacherGroupClassDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    groupClass,
  } = teacherGroupClassDetails;


  const teacherGroupClassUpdate = useSelector(
    (state) => state.teacherGroupClassUpdate
  );
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = teacherGroupClassUpdate;


  useEffect(() => {
    if (successUpdate) {
      // dispatch({ type: TEACHER_GROUP_CLASS_UPDATE_RESET });
      navigate(-1);
    }
    // if (groupClass.id !== classId) {
    //   dispatch(TEACHER_GROUP_CLASS_UPDATE_RESET);
    //   dispatch(detailsTeacherGroupClass(groupId, classId));
    // }
    if (!groupClass) {
      dispatch(detailsTeacherGroupClass(groupId, classId));
    } else {
      setClassNumber(groupClass.class_number);
      setClassTitle(groupClass.class_title);
      setEndDate(groupClass.end_date);
      setStartDate(groupClass.start_date);
    }
  }, [
    dispatch,
    groupClass,
    successUpdate,
    groupId,
    navigate,
    classId, 
    errorUpdate,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateTeacherGroupClass(groupId , {
        id: classId,
        class_number,
        class_title,
        end_date,
        start_date,
      })
    );
  };

  return (
    <React.Fragment>
      <ComeBack />
      <div className="mt-[10px]">
        <div>
          <h2 className="text-[24px] font-semibold">
            {t("editClass")} {classId}
          </h2>
        </div>
        <form className="form" onSubmit={submitHandler}>
          {loadingUpdate && <LoadingBox></LoadingBox>}
          {errorUpdate && (
            <MessageBox variant="danger">{errorUpdate}</MessageBox>
          )}
          {loadingDetails ? (
            <LoadingBox></LoadingBox>
          ) : errorDetails ? (
            <MessageBox variant="danger">{errorDetails}</MessageBox>
          ) : (
            <div className="mt-[30px]">
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="title">{t("title")}</label>
                <input
                  id="title"
                  type="text"
                  className="p-[1rem]"
                  placeholder="Enter Title"
                  value={class_title}
                  onChange={(e) => setClassTitle(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="number">{t("number")}</label>
                <input
                  id="number"
                  type="text"
                  className="p-[1rem]"
                  placeholder="Enter Number"
                  value={class_number}
                  onChange={(e) => setClassNumber(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="startdate">{t("startDate")}</label>
                <input
                  id="startdate"
                  type="date"
                  className="p-[1rem]"
                  placeholder="Enter Start Date"
                  value={start_date}
                  onChange={(e) => setStartDate(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label htmlFor="enddate">{t("endDate")}</label>
                <input
                  id="enddate"
                  type="date"
                  className="p-[1rem]"
                  placeholder="Enter End Date"
                  value={end_date}
                  onChange={(e) => setEndDate(e.target.value)}
                ></input>
              </div>
              <div className="flex flex-col pt-[10px]">
                <label></label>
                <button
                  className="bg-[#08AD76] text-[white] p-[1rem] "
                  type="submit"
                >
                  {t("update")}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default TeacherClassEditScreen;
