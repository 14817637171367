import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LoadingBox from "../../components/HelperComponents/LoadingBox";
import MessageBox from "../../components/HelperComponents/MessageBox";
import { listTeacherGroupClassStudents } from "../../common/redux/actions/studentsActions";
import { useTranslation } from "react-i18next";
import ComeBack from "../../components/HelperComponents/ComeBack";

const TeacherGroupStudentsScreen = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const teacherGroupClassStudentsList = useSelector(
    (state) => state.teacherGroupClassStudentsList
  );

  const { loading, error, students } = teacherGroupClassStudentsList;
  useEffect(() => {
    dispatch(listTeacherGroupClassStudents(id));
  }, [dispatch, id]);

  return (
    <React.Fragment>
      <ComeBack />
      <div>
        <h2 className="mt-[15px] font-bold text-[28px]">
          {t("studentList")}
        </h2>
        <div className="flex flex-col mt-[30px]">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead className="bg-white border-b">
                    <tr>
                      <th
                        scope="col"
                        className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                      >
                        N#
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                      >
                        {t("name")}
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-semibold text-gray-900 px-6 py-4 text-left"
                      >
                        {t("username")}
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <LoadingBox></LoadingBox>
                  ) : error ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                  ) : (
                    <tbody>
                      {students.length === 0 && (
                        <MessageBox>
                          There is no student for this class
                        </MessageBox>
                      )}
                      {students.map((student) => {
                        return (
                          <tr className="bg-gray-100 border-b">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {student.id}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {student.name}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {student.username}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TeacherGroupStudentsScreen;
