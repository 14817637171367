import {
  SCORES_LIST_REQUEST,
  SCORES_LIST_SUCCESS,
  SCORES_LIST_FAIL,
  SCORE_CREATE_REQUEST,
  SCORE_CREATE_SUCCESS,
  SCORE_CREATE_FAIL,
  SCORE_DETAILS_REQUEST,
  SCORE_DETAILS_SUCCESS,
  SCORE_DETAILS_FAIL,
  SCORE_UPDATE_REQUEST,
  SCORE_UPDATE_SUCCESS,
  SCORE_UPDATE_FAIL,
  SCORE_DELETE_REQUEST,
  SCORE_DELETE_SUCCESS,
  SCORE_DELETE_FAIL,
} from "../constants/scoreConstants";
import Axios from "axios";
// Assignment Actions


const BASE_URL = "https://dailystat.org";
export const listScores =
  (groupID, classID, assignmentId) => async (dispatch, getState) => {
    dispatch({ type: SCORES_LIST_REQUEST });
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await Axios.get(
        `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classID}/assignments/${assignmentId}/score/`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access}`,
          },
        }
      );
      dispatch({
        type: SCORES_LIST_SUCCESS,
        payload: data,
      });
    
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: SCORES_LIST_FAIL,
        payload: message,
      });
    }
  };




  export const createScore =
  (groupId, classId, assignmentId, assignmentScore) => async (dispatch, getState) => {
    dispatch({ type: SCORE_CREATE_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.post(
        `${BASE_URL}/course/teacher/api/groups/${groupId}/classes/${classId}/assignments/${assignmentId}/score/`,
      assignmentScore,
        {
          // Handle 401 error
          headers: {
            Authorization: `Bearer ${userInfo.access}`,
          },
        }
      );
      dispatch({
        type: SCORE_CREATE_SUCCESS,
        payload: data.assignmentScore,
      });
    } catch (error) {
      const status = error.response.status;
      let message = "";
      status === 400 ? (message = "Hər bir tələbənin bir qiyməti ola bilər") : (message = "Xəta baş verdi");
      dispatch({ type: SCORE_CREATE_FAIL, payload: message });
    }
  };

export const detailsScore =
  (groupID, classID, assignmentID, scoreId) => async (dispatch, getState) => {
    dispatch({ type: SCORE_DETAILS_REQUEST });
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await Axios.get(
        `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classID}/assignments/${assignmentID}/score/${scoreId}/`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access}`,
          },
        }
      );
      dispatch({
        type: SCORE_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: SCORE_DETAILS_FAIL,
        payload: message,
      });
    }
  };

export const updateScore =
  (groupID, classId, assignmentId, scoreId, scoreDetail) => async (dispatch, getState) => {
    dispatch({
      type: SCORE_UPDATE_REQUEST,
      payload: scoreDetail,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.patch(
        `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classId}/assignments/${assignmentId}/score/${scoreId}/`,
        scoreDetail,
        {
          headers: { Authorization: `Bearer ${userInfo.access}` },
        }
      );
      dispatch({
        type: SCORE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: SCORE_UPDATE_FAIL,
        error: message,
      });
    }
  };

export const deleteScore =
  (groupId, classId, assignmentId, scoreId) => async (dispatch, getState) => {
    dispatch({
      type: SCORE_DELETE_REQUEST,
      payload: { groupId, classId, assignmentId, scoreId },
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = Axios.delete(
        `${BASE_URL}/course/teacher/api/groups/${groupId}/classes/${classId}/assignments/${assignmentId}/score/${scoreId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.access}` },
        }
      );
      dispatch({ type: SCORE_DELETE_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: SCORE_DELETE_FAIL,
        payload: message,
      });
    }
  };
