import {
  TEACHER_GROUP_CLASS_CREATE_REQUEST,
  TEACHER_GROUP_CLASS_CREATE_SUCCESS,
  TEACHER_GROUP_CLASS_CREATE_FAIL,
  TEACHER_GROUP_CLASS_DETAILS_REQUEST,
  TEACHER_GROUP_CLASS_DETAILS_SUCCESS,
  TEACHER_GROUP_CLASS_DETAILS_FAIL,
  TEACHER_GROUP_CLASS_UPDATE_REQUEST,
  TEACHER_GROUP_CLASS_UPDATE_SUCCESS,
  TEACHER_GROUP_CLASS_UPDATE_FAIL,
  TEACHER_GROUP_CLASS_DELETE_REQUEST,
  TEACHER_GROUP_CLASS_DELETE_SUCCESS,
  TEACHER_GROUP_CLASS_DELETE_FAIL,
} from "../constants/classConstants";

import Axios from "axios";

const BASE_URL = "https://dailystat.org";

// Classes Actions
export const createTeacherGroupClass =
  (id, groupClass) => async (dispatch, getState) => {
    dispatch({ type: TEACHER_GROUP_CLASS_CREATE_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.post(
        `${BASE_URL}/course/teacher/api/groups/${id}/classes`,
        groupClass,
        {
          // Handle 401 error
          headers: {
            Authorization: `Bearer ${userInfo.access}`,
          },
        }
      );
      dispatch({
        type: TEACHER_GROUP_CLASS_CREATE_SUCCESS,
        payload: data.groupClass,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: TEACHER_GROUP_CLASS_CREATE_FAIL, payload: message });
    }
  };

export const detailsTeacherGroupClass =
  (groupID, classID) => async (dispatch, getState) => {
    dispatch({
      type: TEACHER_GROUP_CLASS_DETAILS_REQUEST,
      payload: { groupID, classID },
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.get(
        `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classID}/`,
        {
          headers: { Authorization: `Bearer ${userInfo.access}` },
        }
      );
      dispatch({ type: TEACHER_GROUP_CLASS_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: TEACHER_GROUP_CLASS_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// export const updateTeacherGroupClass =
//   (groupID, classId, groupClass) => async (dispatch, getState) => {
//     dispatch({ type: TEACHER_GROUP_CLASS_UPDATE_REQUEST, payload: groupClass });
//     const {
//       userSignin: { userInfo },
//     } = getState();
//     try {
//       const { data } = await Axios.patch(
//         `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classId}/`,
//         groupClass,
//         {
//           headers: {
//             Authorization: `Bearer ${userInfo.access}`,
//           },
//         }
//       );
//       console.log("UPDATED");
//       dispatch({ type: TEACHER_GROUP_CLASS_UPDATE_SUCCESS, payload: data });
//     } catch (error) {
//       const message =
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message;
//       dispatch({ type: TEACHER_GROUP_CLASS_UPDATE_FAIL, error: message });
//       console.log(error);
//       console.log("ERRORRING");
//     }
//   };

export const updateTeacherGroupClass =
  (groupID, groupClass) => async (dispatch, getState) => {
    dispatch({ type: TEACHER_GROUP_CLASS_UPDATE_REQUEST, payload: groupClass });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.patch(
        `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${groupClass.id}/`,
        groupClass,
        {
          headers: { Authorization: `Bearer ${userInfo.access}` },
        }
      );
      dispatch({ type: TEACHER_GROUP_CLASS_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: TEACHER_GROUP_CLASS_UPDATE_FAIL, error: message });
    }
  };

export const deleteTeacherGroupClass =
  (groupId, classId) => async (dispatch, getState) => {
    dispatch({
      type: TEACHER_GROUP_CLASS_DELETE_REQUEST,
      payload: { groupId, classId },
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      Axios.delete(
        `${BASE_URL}/course/teacher/api/groups/${groupId}/classes/${classId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.access}` },
        }
      );
      dispatch({ type: TEACHER_GROUP_CLASS_DELETE_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: TEACHER_GROUP_CLASS_DELETE_FAIL, payload: message });
    }
  };
