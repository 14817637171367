// CLASS ATTENDANCE
export const ATTENDANCE_LIST_REQUEST = "ATTENDANCE_LIST_REQUEST";
export const ATTENDANCE_LIST_SUCCESS = "ATTENDANCE_LIST_SUCCESS";
export const ATTENDANCE_LIST_FAIL = "ATTENDANCE_LIST_FAIL";

export const ATTENDANCE_CREATE_REQUEST = "ATTENDANCE_CREATE_REQUEST";
export const ATTENDANCE_CREATE_SUCCESS = "ATTENDANCE_CREATE_SUCCESS";
export const ATTENDANCE_CREATE_FAIL = "ATTENDANCE_CREATE_FAIL";
export const ATTENDANCE_CREATE_RESET = "ATTENDANCE_CREATE_RESET";


export const ATTENDANCE_UPDATE_REQUEST = "ATTENDANCE_UPDATE_REQUEST";
export const ATTENDANCE_UPDATE_SUCCESS = "ATTENDANCE_UPDATE_SUCCESS";
export const ATTENDANCE_UPDATE_FAIL = "ATTENDANCE_UPDATE_FAIL";
export const ATTENDANCE_UPDATE_RESET = "ATTENDANCE_UPDATE_RESET";

export const ATTENDANCE_DETAILS_REQUEST = "ATTENDANCE_DETAILS_REQUEST";
export const ATTENDANCE_DETAILS_SUCCESS = "ATTENDANCE_DETAILS_SUCCESS";
export const ATTENDANCE_DETAILS_FAIL = "ATTENDANCE_DETAILS_FAIL";

export const ATTENDANCE_DELETE_REQUEST = "ATTENDANCE_DELETE_REQUEST";
export const ATTENDANCE_DELETE_SUCCESS = "ATTENDANCE_DELETE_SUCCESS";
export const ATTENDANCE_DELETE_FAIL = "ATTENDANCE_DELETE_FAIL";
export const ATTENDANCE_DELETE_RESET = "ATTENDANCE_DELETE_RESET";

// ATTENDANCE
export const  ATTENDANCE_STATUS_LIST_REQUEST =  "ATTENDANCE_STATUS_LIST_REQUEST";
export const  ATTENDANCE_STATUS_LIST_SUCCESS =  "ATTENDANCE_STATUS_LIST_SUCCESS";
export const  ATTENDANCE_STATUS_LIST_FAIL =  "ATTENDANCE_STATUS_LIST_FAIL";
