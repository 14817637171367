import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { detailsEnrollment } from "../../common/redux/actions/enrollmentActions";
import StudentClassBox from "../../components/StudentComponents/StudentClassBox";
const StudentClasses = () => {
  const dispatch = useDispatch();
  const enrollmentDetail = useSelector((state) => state.enrollmentDetails);
  const { loading, error, enrollment } = enrollmentDetail;
  useEffect(() => {
    dispatch(detailsEnrollment());
  }, [dispatch]);
  return (
    <React.Fragment>
      <StudentClassBox
        loading={loading}
        error={error}
        enrollment={enrollment}
      />
    </React.Fragment>
  );
};

export default StudentClasses;
