export const SCORES_LIST_REQUEST = "SCORES_LIST_REQUEST";
export const SCORES_LIST_SUCCESS = "SCORES_LIST_SUCCESS";
export const SCORES_LIST_FAIL = "SCORES_LIST_FAIL";

export const SCORE_DETAILS_REQUEST = "SCORE_DETAILS_REQUEST";
export const SCORE_DETAILS_SUCCESS = "SCORE_DETAILS_SUCCESS";
export const SCORE_DETAILS_FAIL = "SCORE_DETAILS_FAIL";

export const SCORE_CREATE_REQUEST = "SCORE_CREATE_REQUEST";
export const SCORE_CREATE_SUCCESS = "SCORE_CREATE_SUCCESS";
export const SCORE_CREATE_FAIL = "SCORE_CREATE_FAIL";
export const SCORE_CREATE_RESET = "SCORE_CREATE_RESET";

export const SCORE_UPDATE_REQUEST = "SCORE_UPDATE_REQUEST";
export const SCORE_UPDATE_SUCCESS = "SCORE_UPDATE_SUCCESS";
export const SCORE_UPDATE_FAIL = "SCORE_UPDATE_FAIL";
export const SCORE_UPDATE_RESET = "SCORE_UPDATE_RESET";

export const SCORE_DELETE_REQUEST = "SCORE_DELETE_REQUEST";
export const SCORE_DELETE_SUCCESS = "SCORE_DELETE_SUCCESS";
export const SCORE_DELETE_FAIL = "SCORE_DELETE_FAIL";
export const SCORE_DELETE_RESET = "SCORE_DELETE_RESET";
