import React, { useState } from "react";
import LoadingBox from "../HelperComponents/LoadingBox";
import MessageBox from "../HelperComponents/MessageBox";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { useTranslation } from "react-i18next";
const StudentClassBox = (props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  const { enrollment, loading, error } = props;
  return (
    <React.Fragment>
      <h2 className="mt-[15px] font-bold text-[20px]">{t("classList")}</h2>
      <h2 className="mt-[15px] font-bold text-[20px]">Attendance Status</h2>
      <div className="w-[100%] p-[10px] flex justify-between flex-wrap  bg-[white] rounded-[5px] mt-[10px]">
        <div className="flex justify-center">
          <div className="w-[20px] h-[20px] bg-[#1BD2C7] rounded-[5px]"></div>
          <p className="font-semibold text-[16px] pl-[5px]">{t("present")}</p>
        </div>
        <div className="flex justify-center">
          <div className="w-[20px] h-[20px]  bg-[#FFAAAA] rounded-[5px]"></div>
          <p className="font-semibold text-[16px] pl-[5px]">{t("absence")}</p>
        </div>
        <div className="flex justify-center">
          <div className="w-[20px] h-[20px]  bg-[#FFC730]  rounded-[5px]"></div>
          <p className="font-semibold text-[16px] pl-[5px]">{t("late")}</p>
        </div>
        <div className="flex justify-center">
          <div className="w-[20px] h-[20px]  bg-[#dc3545]  rounded-[5px]"></div>
          <p className="font-semibold text-[16px] pl-[5px]">
            {t("notAssigned")}
          </p>
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div>
            <div className="grid grid-cols-6 gap-[25px] lg:gap-[0px] bg-[white]  w-[100%] shadow mt-[10px] p-[10px] rounded-[5px] text-left">
              <div className="font-bold">{t("classNumber")}</div>
              <div className="font-bold">{t("classTitle")}</div>
              <div className="font-bold">{t("startDate")}</div>
              <div className="font-bold">{t("endDate")}</div>
              <div className="font-bold">{t("attendance")}</div>
              <div className="font-bold">A/S</div>
            </div>
            {enrollment.group &&
              enrollment.group.class_frs.map((item, i) => {
                return (
                  <div className="shadow">
                    <div
                      className="grid grid-cols-6 items-center text-left w-[100%]  mt-[10px] p-[10px] rounded-[5px] cursor-pointer"
                      onClick={() => toggle(i)}
                    >
                      <div>{item.class_number}</div>
                      <div>{item.class_title}</div>
                      <div>{item.start_date.slice(0, 10)}</div>
                      <div>{item.end_date.slice(0, 10)}</div>
                      {item.attendance ? (
                        <div className="flex items-center">
                          <div
                            className={`w-[15px] h-[15px]  rounded-[3px] cursor-pointer  text-[white] ${
                              item.attendance === "Present"
                                ? "bg-[#1BD2C7]"
                                : item.attendance === "Absent"
                                ? "bg-[#FFAAAA]"
                                : item.attendance === "Late"
                                ? "bg-[#FFC730]"
                                : "bg-[#dc3545]"
                            }`}
                          ></div>
                          <div className="pl-[5px]">{item.attendance}</div>
                        </div>
                      ) : (
                        <div>Not Assigned</div>
                      )}

                      <div className="cursor-pointer">
                        {selected === i ? (
                          <AiOutlineCaretUp />
                        ) : (
                          <AiOutlineCaretDown />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        selected === i ? "font-bold  p-[10px]" : "hidden"
                      }
                    >
                      <h2>{t("classAssignments")}</h2>
                      {item.assignments.length === 0 && (
                        <MessageBox variant="info">
                          {t("assignmentMessage")}
                        </MessageBox>
                      )}
                      {item.assignments &&
                        item.assignments.map((assignment) => {
                          return (
                            <div className="flex justify-between items-center text-left w-[100%] bg-[white] mt-[10px] p-[10px] rounded-[5px] cursor-pointer">
                              <div>
                                <div>
                                  {t("title")} - {assignment.title}
                                </div>
                                <a
                                  href={assignment.file}
                                  download
                                  target="_blank"
                                >
                                  <div className="text-[#1BD2C7] pt-[5px]">
                                    {t("downloadFile")}
                                  </div>
                                </a>
                                <div className="text-[14px] font-bold pt-[5px] opacity-[0.8]">
                                  {t("description")} - {assignment.description}
                                </div>
                                <div className="text-[14px] pt-[5px]">
                                  {t("scoreComment")} - {assignment.score}
                                </div>
                                <div className="text-[14px] pt-[5px]">
                                  {t("dueDate")} - 01.05.2023
                                </div>
                              </div>
                              <div className="text-[24px] text-[#1BD2C7] px-[15px]">
                                {assignment.score ? assignment.score : "N/A"}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default StudentClassBox;
