import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import {
  userSigninReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userUpdatePasswordReducer,
} from "../reducers/userReducers";

import { newsListReducer, newsDetailsReducer } from "../reducers/newsReducers";

import {
  enrollmentDetailsReducer,
} from "../reducers/enrollmentReducers";

import {
  teacherGroupsListReducer,
  teacherGroupDetailsReducer,
} from "../reducers/groupReducers";

import {
  teacherGroupClassCreateReducer,
  teacherGroupClassDeleteReducer,
  teacherGroupClassUpdateReducer,
  teacherGroupClassDetailsReducer,
} from "../reducers/classReducers";

import {
  assignmentsListReducer,
  assignmentCreateReducer,
  assignmentDetailsReducer,
  assignmentUpdateReducer,
  assignmentDeleteReducer,
} from "../reducers/assignmentReducers";

import { teacherGroupClassStudentsListReducer } from "../reducers/studentsReducers";

import {
  attendanceStatusListReducer,
  attendancesListReducer,
  attendanceCreateReducer,
  attendanceUpdateReducer,
  attendanceDetailsReducer,
  attendanceDeleteReducer,
} from "../reducers/attendanceReducers";

import {
  scoresListReducer,
  scoreCreateReducer,
  scoreDetailsReducer,
  scoreUpdateReducer,
  scoreDeleteReducer,
} from "../reducers/scoreReducers";

import {
  studentPrimaryStatisticsListReducer,
  studentSecondaryStatisticsListReducer,
  teacherStatisticsListReducer,
} from "../reducers/statisticsReducers";

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdatePassword: userUpdatePasswordReducer,
  newsList: newsListReducer,
  newsDetails: newsDetailsReducer,
  enrollmentDetails: enrollmentDetailsReducer,
  attendanceStatusList: attendanceStatusListReducer,
  attendancesList: attendancesListReducer,
  attendanceCreate: attendanceCreateReducer,
  attendanceDetails: attendanceDetailsReducer,
  attendanceUpdate: attendanceUpdateReducer,
  attendanceDelete: attendanceDeleteReducer,
  teacherGroupsList: teacherGroupsListReducer,
  teacherGroupDetails: teacherGroupDetailsReducer,
  teacherGroupClassDetails: teacherGroupClassDetailsReducer,
  teacherGroupClassCreate: teacherGroupClassCreateReducer,
  teacherGroupClassUpdate: teacherGroupClassUpdateReducer,
  teacherGroupClassDelete: teacherGroupClassDeleteReducer,
  assignmentsList: assignmentsListReducer,
  assignmentCreate: assignmentCreateReducer,
  assignmentDetails: assignmentDetailsReducer,
  assignmentUpdate: assignmentUpdateReducer,
  assignmentDelete: assignmentDeleteReducer,
  scoresList: scoresListReducer,
  scoreCreate: scoreCreateReducer,
  scoreDetails: scoreDetailsReducer,
  scoreUpdate: scoreUpdateReducer,
  scoreDelete: scoreDeleteReducer,
  teacherGroupClassStudentsList: teacherGroupClassStudentsListReducer,
  studentPrimaryStatisticsList: studentPrimaryStatisticsListReducer,
  studentSecondaryStatisticsList: studentSecondaryStatisticsListReducer,
  teacherStatisticsList: teacherStatisticsListReducer,
});
const initialState = {
  userSignin: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  }
};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
