import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // language resources
  resources: {
    az: {
      translation: {
        // Profile
        name: "Ad",
        firstName: "Ad",
        lastName: "Soyad",
        email: "Email",
        password: "Şifrə",
        oldPassword: "Köhnə şifrə",
        newPassword: "Yeni şifrə",
        confirmPassword: "Şifrəni təsdiqlə",
        birthday: "Doğum tarixi",
        fatherName: "Ata adı",
        contactNumber: "Əlaqə nömrəsi",
        address: "Ünvan",
        // SideBar
        profile: "Profil",
        groups: "Qruplar",
        signout: "Çıxış",
        // News
        news: "Xəbərlər",
        doyouwantchange: "Şifrənizi dəyişmək istəyirsiniz?",
        // General
        save: "Yadda saxla",
        edit: "Dəyiş",
        delete: "Sil",
        username: "İstifadəçi adı",
        startDate: "Başlama tarixi",
        endDate: "Bitmə tarixi",
        class: "Sinif",
        actions: "Əməliyyatlar",
        status: "Status",
        student: "Tələbə",
        addClass: "Dərs əlavə et",
        addAssignment: "Tapşırıq əlavə et",
        updateAssignment: "Tapşırıqı yenilə",
        addAttendance: "Davamiyyəti yoxla",
        attendances: "Davamlılıq",
        assignments: "Tapşırıqlar",
        viewStudents: "Tələbələri gör",
        exportoExcel: "Excelə çıxar",
        score: "Bal",
        scores: "Ballar",
        addScore: "Bal əlavə et",
        title: "Başlıq",
        description: "Açıqlama",
        file: "Fayl",
        assignment: "Tapşırıq",
        attendance: "Davamiyyət",
        comment: "Şərh",
        createClass: "Dərs əlavə et",
        update: "Yenilə",
        number: "Nömrə",
        editClass: "Dərsi yenilə",
        teacher: "Müəllim",
        present: "Iştirak edib",
        absence: "Iştirak etmir",
        late: "Gecikib",
        dashboard: "Idarə paneli",
        viewRanking: "Sıralamaya bax",
        classCount: "Dərs sayı",
        totalScore: "Toplam bal",
        totalPointsScore: "Toplam bal sayı",
        averageScore: "Orta bal",
        totalAssignment: "Tapşırıq sayı",
        groupRanking: "Qrup sıralaması",
        classList: "Sənin dərslərin və tapşırıqlarınız",
        yourClasses: "Ваши классы",
        notAssigned: "Qeyd edilməyib",
        classTitle: "Dərs adı",
        classNumber: "Dərs nömrəsi",
        welcome: "Tələbə panelinə xoş gəlmisiniz",
        yourGroups: "Sənin qrupların",
        dueDate: "Bitmə tarixi",
        assignmentMessage: "Bu sinifə aid tapşırıq yoxdur",
        classAssignments: "Dərs tapşırıqları",
        createAssignment: "Tapşırıq əlavə et",
        downloadFile: "Faylı yüklə",
        scoreComment: "Şərh",
        loading: "Gözləyin...",  
        studentList: "  Tələbələrin siyahısı",
        createClassTitle: "Dərs əlavə et",
        groupRankingList: "Qrup sıralaması",
        noStudentInThisGroup: "Bu qrupda tələbə yoxdur",
        addAttendanceTitle: "Davamiyyəti yoxla",
        comeBack: "Geri qayıt",
        profileUpdatedSuccessfully: "Profil yeniləndi",
        contact: "Əlaqə",
        payment: "Ödəniş",
        statistics: "Statistika",
        groupSidebar: "Qruplar",
      },
    },
    en: {
      translation: {
        // Profile
        name: "Name",
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        password: "Password",
        oldPassword: "Old Password",
        confirmPassword: "Confirm Password",
        newPassword: "New Password",
        birthday: "Birthday",
        fatherName: "Father name",
        contactNumber: "Contact Number",
        address: "Address",
        // SideBar
        profile: "Profile",
        groups: "Groups",
        signout: "Sign out",
        // News
        news: "News",
        doyouwantchange: "Do you want to change your password?",
        // General
        save: "Save",
        edit: "Edit",
        delete: "Delete",
        username: "Username",
        startDate: "Start date",
        endDate: "End date",
        actions: "Actions",
        class: "Class",
        status: "Status",
        student: "Student",
        addClass: "Add Class",
        addAssignment: "Add Assignment",
        viewStudents: "View Students",
        attendances: "Attendances",
        assignments: "Assignments",
        exportoExcel: "Export to excel",
        scores: "Points",
        addScore: "Add Score",
        title: "Title",
        description: "Description",
        file: "File",
        assignment: "Assignment",
        attendance: "Attendance",
        present: "Present",
        absence: "Absent",
        late: "Late",
        comment: "Comment",
        teacher: "Teacher",
        dashboard: "Dashboard",
        viewRanking: "View Ranking",
        classCount: "Class count",
        totalScore: "Total score",
        totalPointsScore: "Total points score",
        averageScore: "Average score",
        totalAssignment: "Total assignments",
        groupRanking: "Group ranking",
        yourClasses: "Your classes and homeworks",
        classList: "Your classes",
        notAssigned: "Not assigned",
        classTitle: "Class Title",
        classNumber: "Class Number",
        welcome: "Welcome to the student panel",
        yourGroups: "Your groups",
        dueDate: "Due date",
        assignmentMessage: "There is no assignment for this class",
        classAssignments: "Class assignments",
        downloadFile: "Download file",
        scoreComment: "Score comment",
        loading: "Loading...",
        studentList: "Student list",
        createClass: "Create class",
        update: "Update",
        number: "Number",
        createClassTitle: "Create class for group",
        createAssignment: "Create assignment",
        groupRankingList: "Group ranking list",
        noStudentInThisGroup: "There is no student in this group",
        addAttendanceTitle: "Add attendance",
        comeBack: "Come back",
        profileUpdatedSuccessfully: "Profile updated succesfully",
        contact: "Contact",
        payment: "Payment",
        statistics: "Statistics",
        groupSidebar: "Groups",
      },
    },
    ru: {
      translation: {
        // Profile
        name: "Имя",
        firstName: "Имя",
        lastName: "Фамилия",
        email: "Электронное ",
        password: "Пароль",
        oldPassword: "Прежний пароль",
        newPassword: "Новый пароль",
        confirmPassword: "Подтвердите пароль",
        birthday: "Дата рождения",
        fatherName: "имя Отца",
        contactNumber: "Контактный телефон",
        address: "Адрес",
        doyouwantchange: "Вы хотите изменить свой пароль?",
        // Student
        classCount: "Номер урока",
        // SideBar
        username: "Имя пользователя",
        profile: "Профиль",
        groups: "Группы",
        signout: "Выход",
        // News
        news: "Новости",
        // General
        save: "Сохранить",
        edit: "Редактировать",
        delete: "Удалить",
        startDate: " Дата начала",
        endDate: "Дата окончания",
        actions: "Действия",
        class: "Класс",
        status: "Статус",
        student: "Студент",
        addClass: "Добавить класс",
        addAssignment: "Добавить задание",
        attendances: "Посещаемость",
        assignments: "Задания",
        viewStudents: "Просмотр студентов",
        exportoExcel: " Экспорт в Excel",
        scores: "Оценки",
        addScore: "Добавить оценку",
        title: "Заголовок",
        description: "Описание",
        file: "Файл",
        assignment: "Задание",
        attendance: "Посещаемость",
        present: "Присутствует",
        absence: "Отсутствует",
        late: "Поздно",
        comment: "Комментарий",
        teacher: "Учитель",
        dashboard: "Щиток приборов",
        viewRanking: "Посмотреть рейтин",
        totalScore: "Общий счет",
        totalPointsScore: "Общий балл",
        averageScore: "Средний балл",
        totalAssignment: "Номер задачи",
        groupRanking: "Групповое зачет",
        yourClasses: "Ваши классы",
        classList: "Ваши уроки и домашние задания",
        notAssigned: "Не упомянуто",
        classTitle: "Название урока",
        classNumber: "Номер урока",
        welcome: "Добро пожаловать в панель студента",
        yourGroups: "Ваши группы",
        dueDate: "Срок сдачи",
        assignmentMessage: "Нет задания для этого класса",
        classAssignments: "Задание для класса",
        downloadFile: "Скачать файл",
        scoreComment: "Комментарий к оценке",
        loading: "Загрузка...",
        studentList: "Список студентов",
        createClass: "Создать класс",
        update: "Обновить",
        number: "Номер",
        createClassTitle: "Создать класс для группы",
        createAssignment: "Создать задание",
        groupRankingList: "Список групповых рейтингов",
        noStudentInThisGroup: "Нет студентов в этой группе",
        addAttendanceTitle: "Добавить посещаемость",
        comeBack: "Вернуться",
        profileUpdatedSuccessfully: "Профиль успешно обновлен",
        contact: "Контакт",
        payment: "Оплата",
        statistics: "Статистика",
        groupSidebar: "Группы",
      },
    },
  },
});

export default i18n;
