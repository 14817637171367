import React, { useState } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { BiWorld, BiUserCircle } from "react-icons/bi";
import { RiGroup2Line } from "react-icons/ri";
import { MdContactSupport, MdPayments, MdAssignment } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { signout } from "../../common/redux/actions/userActions";
import { useTranslation } from "react-i18next";

const SideBar = (props) => {
  const { t } = useTranslation();
  const { user } = props;
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
  };

  const [toggle, setToggle] = useState(false);
  let sideBarList = [];

  if(user?.type === "student"){
    sideBarList = [
      {
       id: 1,
       name: t("groupSidebar"),
       icon: <RiGroup2Line />,
       url: "/",
     },
     {
       id: 2,
       name: t("news"),
       icon: <BiWorld />,
       url: "/news",
     },
     {
       id: 3,
       name: t("profile"),
       icon: <BiUserCircle />,
       url: "/profile",
     },
     {
       id: 4,
       name: t("assignments"),
       icon: <MdAssignment />,
       url: "/assignments",
     },
     {
       id: 5,
       name: t("contact"),
       icon: <MdContactSupport />,
       url: "/contact",
     },
     {
       id: 6,
       name: t("payment"),
       icon: <MdPayments />,
       url: "/payment",
     }, 
   ];
  } else {
    sideBarList = [
      {
       id: 1,
       name: t("groupSidebar"),
       icon: <RiGroup2Line />,
       url: "/",
     },
     {
       id: 2,
       name: t("news"),
       icon: <BiWorld />,
       url: "/news",
     },
     {
       id: 3,
       name: t("profile"),
       icon: <BiUserCircle />,
       url: "/profile",
     },
     {
       id: 4,
       name: t("contact"),
       icon: <MdContactSupport />,
       url: "/contact",
     },
     {
       id: 5,
       name: t("payment"),
       icon: <MdPayments />,
       url: "/payment",
     }, 
   ];
  }
  return (
    <React.Fragment>
      <div
        className={
          toggle
            ? "w-[30%] lg:w-[15%] bg-[#222631] text-[white] lg:rounded-[15px] relative transition-all duration-200 ease-in-out"
            : "w-[15%] lg:max-w-[5%] lg:w-[5%] bg-[#222631] text-[white] lg:rounded-[15px] relative transition-all duration-500 ease-in-out"
        }
      >
        <div>
          <div
            onClick={() => setToggle(!toggle)}
            className={
              toggle
                ? "absolute top-[10px] right-[5%] text-[25px] cursor-pointer"
                : "absolute top-[10px] right-[50%] translate-x-[50%] text-[25px] cursor-pointer"
            }
          >
            <GiHamburgerMenu />
          </div>
          <Link to="/">
            <div className={toggle ? "w-[100%] pt-[50px] py-[20px]" : "hidden"}>
              <h2 className="text-center text-[16px]  lg:text-[24px] font-bold">
                BAKU IT <br></br>ACADEMY
              </h2>
            </div>
          </Link>
        </div>

        {/* <div className={toggle ? "w-[100%] flex flex-col  lg:justify-start cursor-pointer" : "w-[100%] flex flex-col  lg:justify-start cursor-pointer mt-[50px]"}>
          {loadingUserDetails ? (
            <LoadingBox />
          ) : errorUserDetails ? (
            <MessageBox variant="danger">{errorUserDetails}</MessageBox>
          ) : user.type === "teacher" ? (
            <>
              <Link to="/teacher/groups">
                <div className="w-[100%] flex items-center justify-center lg:justify-start pt-[25px] cursor-pointer">
                  <div className="text-[25px] lg:pl-[30px]">
                    <AiOutlineGroup />
                  </div>
                  <div className={toggle ? "pl-[10px] hidden" : "block"}>
                    {t("groups")}
                  </div>
                </div>
              </Link>
            </>
          ) : (
            <Link to="/enrollments/">
            <div className="w-[100%] flex  justify-center lg:justify-start pt-[25px] cursor-pointer">
              <div className={toggle ? "lg:pl-[30px] text-[25px]" : "lg:pl-[25px] text-[25px]"}>
                <AiOutlineProfile />
              </div>
              <div className={toggle ? "pl-[10px] lg:block  hidden" : "hidden"}>
                Qruppalar
              </div>     
            </div>
            </Link>
          )}
        </div> */}
        <div className="w-[100%] flex flex-col mt-[40px]">
          {sideBarList.map((item) => {
            return (
              <Link to={item.url}>
                <div
                  className="w-[100%] flex items-center justify-center lg:justify-start pt-[25px] cursor-pointer"
                  key={item.id}
                >
                  <div
                    className={
                      toggle
                        ? "lg:pl-[30px] text-[25px] text-[white]"
                        : "lg:pl-[30px] text-[25px] text-[white]"
                    }
                  >
                    {item.icon}
                  </div>
                  <div
                    className={toggle ? "pl-[10px] lg:block  hidden" : "hidden"}
                  >
                    {item.name}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>

        <div
          className={
            toggle
              ? "w-[100%]  absolute top-[85%]"
              : "w-[100%]  absolute top-[85%]"
          }
        >
          <Link to="#signout" onClick={signoutHandler}>
            <div className="w-[100%] flex items-center justify-center lg:justify-start pt-[25px] cursor-pointer">
              <div
                className={
                  toggle
                    ? "lg:pl-[30px] text-[25px]"
                    : "lg:pl-[25px] text-[25px]"
                }
              >
                <AiOutlineLogout />
              </div>
              <div className={toggle ? "pl-[10px] hidden lg:block" : "hidden"}>
                {t("signout")}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SideBar;
