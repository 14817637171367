import React from 'react'

const Payment = () => {
  return (
    <React.Fragment>
      <div className='bg-[#222631] text-[white] text-center rounded-[10px] h-[40%] flex items-center justify-center'>
      <h2 className='text-[16px] lg:text-[24px] font-bold'>Ödəniş üçün zəhmət olmasa kurs rəhbərliyinə yaxınlaşın.</h2>
      </div>
    </React.Fragment>
  )
}

export default Payment