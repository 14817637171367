


export const TEACHER_GROUP_CLASS_CREATE_REQUEST = "TEACHER_GROUP_CLASS_CREATE_REQUEST";
export const TEACHER_GROUP_CLASS_CREATE_SUCCESS = "TEACHER_GROUP_CLASS_CREATE_SUCCESS";
export const TEACHER_GROUP_CLASS_CREATE_FAIL = "TEACHER_GROUP_CLASS_CREATE_FAIL";
export const TEACHER_GROUP_CLASS_CREATE_RESET = "TEACHER_GROUP_CLASS_CREATE_RESET";


export const TEACHER_GROUP_CLASS_UPDATE_REQUEST = "TEACHER_GROUP_CLASS_UPDATE_REQUEST";
export const TEACHER_GROUP_CLASS_UPDATE_SUCCESS = "TEACHER_GROUP_CLASS_UPDATE_SUCCESS";
export const TEACHER_GROUP_CLASS_UPDATE_FAIL = "TEACHER_GROUP_CLASS_UPDATE_FAIL";
export const TEACHER_GROUP_CLASS_UPDATE_RESET = "TEACHER_GROUP_CLASS_UPDATE_RESET";

export const TEACHER_GROUP_CLASS_DELETE_REQUEST = "TEACHER_GROUP_CLASS_DELETE_REQUEST";
export const TEACHER_GROUP_CLASS_DELETE_SUCCESS = "TEACHER_GROUP_CLASS_DELETE_SUCCESS";
export const TEACHER_GROUP_CLASS_DELETE_FAIL = "TEACHER_GROUP_CLASS_DELETE_FAIL";
export const TEACHER_GROUP_CLASS_DELETE_RESET = "TEACHER_GROUP_CLASS_DELETE_RESET";

export const TEACHER_GROUP_CLASS_DETAILS_REQUEST = "TEACHER_GROUP_CLASS_DETAILS_REQUEST";
export const TEACHER_GROUP_CLASS_DETAILS_SUCCESS = "TEACHER_GROUP_CLASS_DETAILS_SUCCESS";
export const TEACHER_GROUP_CLASS_DETAILS_FAIL = "TEACHER_GROUP_CLASS_DETAILS_FAIL";