import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { detailsNews } from "../../common/redux/actions/newsActions";
import { useSelector, useDispatch } from "react-redux";
import { MdDateRange } from "react-icons/md";
import ComeBack from "../../components/HelperComponents/ComeBack";
const NewsDetailsScreen = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const newsDetail = useSelector((state) => state.newsDetails);
  const { loading, error, newsDetails } = newsDetail;

  useEffect(() => {
    dispatch(detailsNews(slug));
  }, [dispatch, slug]);

  const createMarkup = () => {
    return { __html: newsDetails.content };
  };

  return (
    <React.Fragment>
      <ComeBack />
      <div>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div className="">
            <div>
              <div className="w-[100%] md:h-[30vh] lg:h-[50vh] mt-[20px]">
                <img
                  className="w-[100%] h-[100%] bg-cover object-cover rounded-[5px]"
                  src={newsDetails.cover}
                  alt={newsDetails.title}
                />
              </div>
              <div className="p-[15px]">
                <div className="flex flex-col lg:flex-row lg:items-center justify-between w-[auto]">
                  <div>
                    <h2 className="font-bold text-[22px] lg:text-[30px]">
                      {newsDetails.title}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="w-[100%] pl-[15px] pr-[15px] m-[auto]">
                <div className="flex items-center w-[auto]">
                  <div>
                    <MdDateRange />
                  </div>
                  <div>{new Date(newsDetails.created_at).toDateString()}</div>
                </div>
                <hr />
                <div>
                  <p
                    className="w-[95%] md:text-lg text-sm my-[20px] mx-[5px]"
                    dangerouslySetInnerHTML={createMarkup()}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default NewsDetailsScreen;
