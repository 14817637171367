import Axios from "axios";
import {
  NEWS_LIST_REQUEST,
  NEWS_LIST_SUCCESS,
  NEWS_LIST_FAIL,
  NEWS_DETAILS_REQUEST,
  NEWS_DETAILS_SUCCESS,
  NEWS_DETAILS_FAIL,
} from "../constants/newsConstants";


const BASE_URL = "https://dailystat.org";
export const listNews = () => async (dispatch, getState) => {
  dispatch({ type: NEWS_LIST_REQUEST });
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(`${BASE_URL}/news/api/news`, {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    });
    dispatch({ type: NEWS_LIST_SUCCESS, payload: data });
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: NEWS_LIST_FAIL, payload: message });
  }
};
export const detailsNews = (slug) => async (dispatch, getState) => {
  dispatch({ type: NEWS_DETAILS_REQUEST , payload: slug});
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.get(`${BASE_URL}/news/api/news/${slug}`, {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
      },
    });
    dispatch({ type: NEWS_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: NEWS_DETAILS_FAIL, payload: message });
  }
};

