import {
  ASSIGNMENTS_LIST_REQUEST,
  ASSIGNMENTS_LIST_SUCCESS,
  ASSIGNMENTS_LIST_FAIL,
  ASSIGNMENT_CREATE_REQUEST,
  ASSIGNMENT_CREATE_SUCCESS,
  ASSIGNMENT_CREATE_FAIL,
  ASSIGNMENT_DETAILS_REQUEST,
  ASSIGNMENT_DETAILS_SUCCESS,
  ASSIGNMENT_DETAILS_FAIL,
  ASSIGNMENT_UPDATE_REQUEST,
  ASSIGNMENT_UPDATE_SUCCESS,
  ASSIGNMENT_UPDATE_FAIL,
  ASSIGNMENT_DELETE_REQUEST,
  ASSIGNMENT_DELETE_SUCCESS,
  ASSIGNMENT_DELETE_FAIL,
} from "../constants/assignmentConstants";
import Axios from "axios";
// Assignment Actions

const BASE_URL = "https://dailystat.org";

export const listAssignments =
  (groupID, classID) => async (dispatch, getState) => {
    dispatch({ type: ASSIGNMENTS_LIST_REQUEST });
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await Axios.get(
        `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classID}/assignments`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access}`,
          },
        }
      );
      dispatch({
        type: ASSIGNMENTS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ASSIGNMENTS_LIST_FAIL,
        payload: message,
      });
    }
  };


  export const createAssignment =
  (groupId, classId, assignment) => async (dispatch, getState) => {
    dispatch({ type: ASSIGNMENT_CREATE_REQUEST });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.post(
        `${BASE_URL}/course/teacher/api/groups/${groupId}/classes/${classId}/assignments/`,
        assignment,
        {
          // Handle 401 error
          headers: {
            Authorization: `Bearer ${userInfo.access}`,
       
          },
        }
      );
      dispatch({
        type: ASSIGNMENT_CREATE_SUCCESS,
        payload: data.assignment,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ASSIGNMENT_CREATE_FAIL, payload: message });
    }
  };

export const detailsAssignment =
  (groupID, classID, assignmentID) => async (dispatch, getState) => {
    dispatch({ type: ASSIGNMENT_DETAILS_REQUEST });
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await Axios.get(
        `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classID}/assignments/${assignmentID}/`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access}`,
          },
        }
      );
      dispatch({
        type: ASSIGNMENT_DETAILS_SUCCESS,
        payload: data,
      });
      
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ASSIGNMENT_DETAILS_FAIL,
        payload: message,
      });
    }
  };

export const updateAssignment =
  (groupID, classId, assignmentId, assignment) => async (dispatch, getState) => {
    dispatch({
      type: ASSIGNMENT_UPDATE_REQUEST,
      payload: assignment,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await Axios.patch(
        `${BASE_URL}/course/teacher/api/groups/${groupID}/classes/${classId}/assignments/${assignmentId}/`,
        assignment,
        {
          headers: { Authorization: `Bearer ${userInfo.access}` },
        }
      );
      dispatch({
        type: ASSIGNMENT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ASSIGNMENT_UPDATE_FAIL,
        error: message,
      });
    }
  };

export const deleteAssignment =
  (groupId, classId, assignmentId) => async (dispatch, getState) => {
    dispatch({
      type: ASSIGNMENT_DELETE_REQUEST,
      payload: { groupId, classId, assignmentId },
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = Axios.delete(
        `${BASE_URL}/course/teacher/api/groups/${groupId}/classes/${classId}/assignments/${assignmentId}`,
        {
          headers: { Authorization: `Bearer ${userInfo.access}` },
        }
      );
      dispatch({ type: ASSIGNMENT_DELETE_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ASSIGNMENT_DELETE_FAIL,
        payload: message,
      });
    }
  };
