import React from "react";
import { Link } from "react-router-dom";
const NewsBox = (props) => {
  const { item } = props;
  console.log(item);
  const createMarkup = () => {
    return { __html: item.content };
  };
  return (
    <React.Fragment>
      <div className="shadow p-[10px] rounded-[10px]">
        <div className="w-[100%] h-[250px]">
          <img
            className="w-[100%] h-[100%] rounded-[10px]"
            alt=""
            src={item.cover}
          />
        </div>
        <div>
          <h2 className="font-bold pt-[5px] text-[20px]">{item.title}</h2>
          <p dangerouslySetInnerHTML={createMarkup()}></p>
        </div>

        <Link to={`/news/${item.slug}`}>
          <div className="w-[100%] pt-[5px]">
            <button className="bg-[#222631] mt-[5px] text-[white] py-[10px] w-[100%]">
              Read More
            </button>
          </div>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default NewsBox;
