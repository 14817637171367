import {
  ATTENDANCE_STATUS_LIST_REQUEST,
  ATTENDANCE_STATUS_LIST_SUCCESS,
  ATTENDANCE_STATUS_LIST_FAIL,
  ATTENDANCE_LIST_REQUEST,
  ATTENDANCE_LIST_SUCCESS,
  ATTENDANCE_LIST_FAIL,
  ATTENDANCE_DELETE_FAIL,
  ATTENDANCE_DELETE_SUCCESS,
  ATTENDANCE_DELETE_REQUEST,
  ATTENDANCE_CREATE_FAIL,
  ATTENDANCE_CREATE_SUCCESS,
  ATTENDANCE_CREATE_REQUEST,
  ATTENDANCE_CREATE_RESET,
  ATTENDANCE_DETAILS_FAIL,
  ATTENDANCE_DETAILS_SUCCESS,
  ATTENDANCE_DETAILS_REQUEST,
  ATTENDANCE_UPDATE_FAIL,
  ATTENDANCE_UPDATE_REQUEST,
  ATTENDANCE_UPDATE_SUCCESS,
  ATTENDANCE_UPDATE_RESET,
} from "../constants/attendanceConstants";

export const attendanceStatusListReducer = (
  state = { loading: true, statusList: [] },
  action
) => {
  switch (action.type) {
    case ATTENDANCE_STATUS_LIST_REQUEST:
      return { loading: true };
    case ATTENDANCE_STATUS_LIST_SUCCESS:
      return { loading: false, statusList: action.payload };
    case ATTENDANCE_STATUS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const attendancesListReducer = (
  state = { loading: true, attendances: [] },
  action
) => {
  switch (action.type) {
    case ATTENDANCE_LIST_REQUEST:
      return { loading: true };
    case ATTENDANCE_LIST_SUCCESS:
      return { loading: false, attendances: action.payload };
    case ATTENDANCE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const attendanceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDANCE_CREATE_REQUEST:
      return { loading: true };
    case ATTENDANCE_CREATE_SUCCESS:
      return { loading: false, success: true, attendance: action.payload };
    case ATTENDANCE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDANCE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};


export const attendanceDetailsReducer = (
  state = { loading: true },
  action
) => {
  switch (action.type) {
    case ATTENDANCE_DETAILS_REQUEST:
      return { loading: true };
    case ATTENDANCE_DETAILS_SUCCESS:
      return { loading: false, attendance: action.payload };
    case ATTENDANCE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const attendanceUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDANCE_UPDATE_REQUEST:
      return { loading: true };
    case ATTENDANCE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ATTENDANCE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDANCE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};


export const attendanceDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDANCE_DELETE_REQUEST:
      return { loading: true };
    case ATTENDANCE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ATTENDANCE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}